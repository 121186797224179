import { Box, Button, TextField, Typography } from "@material-ui/core";
import "../home/homeStyle.css";
import { Chart } from "react-google-charts";
import { Outlet } from "react-router-dom";
import Box8 from "../home/box8";
import { Legend } from "./sheet3";
import {
  data10Legend,
  data11Legend,
  data12Legend,
  data17Legend,
  data5Legend,
  data9Legend,
  dataLegend,
} from "./sheetData";
import { Loader } from "../components/loader";
import React, { useEffect, useState, Suspense } from "react";
import {
  data,
  data2,
  data3,
  data4,
  data5,
  data6,
  data7,
  data8,
  data9,
  data10,
  data11,
  data12,
  data13,
  data14,
  data15,
  data16,
  data17,
  data18,
  data19,
  data20,
  data21,
  data22,
  data23,
  data24,
  data25,
  data26,
  data27,
  data28,
  data29,
  data30,
  data31,
  data32,
  data33,
  data34,
  data35,
  data36,
  data37,
  data38,
  options2,
  options3,
  options4,
  options5,
  options6,
  options7,
  options8,
  options9,
  options10,
  options11,
  options12,
  options13,
  options14,
  options15,
  options16,
  options17,
  options18,
  options19,
  options20,
  options21,
  options22,
  options23,
  options24,
  options25,
  options26,
  options27,
  options28,
  options29,
  options30,
  options31,
  options32,
  options33,
  options34,
  options35,
  options36,
  options37,
  options38,
  options,
} from "./sheet1data";
import ReactGA from "react-ga4";
// const { Chart } = React.lazy(() => import("react-google-charts"));

function Sheet1() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Test Session Scores General Example",
    });
  }, []);
  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 600 }}>
        Test Session Scores General Example
      </Typography>
      {/* <Loader /> */}
      <div className="sheet1">
        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data}
            loader={<Loader />}
            options={options}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          />
          <div className="graphWithLegend">
            <Legend legends={dataLegend} />
          </div>
        </div>

        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data2}
            options={options2}
          />
          <div className="graphWithLegend">
            <Legend legends={dataLegend} />
          </div>
        </div>
        <Chart
          chartType="PieChart"
          data={data3}
          options={options3}
          width={"100%"}
          height={"400px"}
        />
        <Chart
          chartType="PieChart"
          data={data4}
          options={options4}
          width={"100%"}
          height={"400px"}
        />

        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data5}
            options={options5}
          />
          <div className="graphWithLegend">
            <Legend legends={data5Legend} />
          </div>
        </div>
        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data6}
            options={options6}
          />
          <div className="graphWithLegend">
            <Legend legends={data5Legend} />
          </div>
        </div>
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data7}
          options={options7}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data8}
          options={options8}
        />
        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data9}
            options={options9}
          />
          <div className="graphWithLegend">
            <Legend legends={data9Legend} />
          </div>
        </div>
        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data10}
            options={options10}
          />
          <div className="graphWithLegend">
            <Legend legends={data10Legend} />
          </div>
        </div>
        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data11}
            options={options11}
          />
          <div className="graphWithLegend">
            <Legend legends={data11Legend} />
          </div>
        </div>
        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data12}
            options={options12}
          />
          <div className="graphWithLegend">
            <Legend legends={data12Legend} />
          </div>
        </div>
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data13}
          options={options13}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data14}
          options={options14}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data15}
          options={options15}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data16}
          options={options16}
        />
        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data17}
            options={options17}
          />
          <div className="graphWithLegend">
            <Legend legends={data17Legend} />
          </div>
        </div>
        <div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={data18}
            options={options18}
          />
          <div className="graphWithLegend">
            <Legend legends={data17Legend} />
          </div>
        </div>
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data19}
          options={options19}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data20}
          options={options20}
        />
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data21}
          options={options21}
        />
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data22}
          options={options22}
        />
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data23}
          options={options23}
        />
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data24}
          options={options24}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data25}
          options={options25}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data26}
          options={options26}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data27}
          options={options27}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data28}
          options={options28}
        />
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data29}
          options={options29}
        />
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data30}
          options={options30}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data31}
          options={options31}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data32}
          options={options32}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data33}
          options={options33}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data34}
          options={options34}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data35}
          options={options35}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data36}
          options={options36}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data37}
          options={options37}
        />
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data38}
          options={options38}
        />
      </div>
    </>
  );
}

export default Sheet1;
