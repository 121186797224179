import { Box, Button, TextField, Typography } from "@material-ui/core";
import react, { useEffect, useRef, useState } from "react";
import background from "../assets/background5.png";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactGA from "react-ga4";
function Box5() {
  return (
    <section id="faq" className="box5Style">
      <Box style={{ display: "flex", flexDirection: "column", width: "80%" }}>
        <Typography
          variant="h5"
          style={{
            color: "#F26723",
            textTransform: "uppercase",
            fontWeight: 600,
            marginBottom: "13px",
            alignSelf: "center",
          }}
        >
          FAQ
        </Typography>
        <Typography
          variant="h2"
          style={{ alignSelf: "center", marginBottom: "41px" }}
        >
          Frequently Asked Questions
        </Typography>
        <Faq
          question="What is the goal of this website?"
          answer="To peak your curiosity enough so we can have a more detailed discussion about your school’s needs. This website simply is here for you to take the next step and contact me. "
        />
        <Faq
          question="I see some examples on the website. Do you have others?"
          answer="Yes, I literally have hundreds of artifacts we can review."
        />
        <Faq
          question="What is your goal when working with a school?"
          answer="To provide exceptional service so that you will trust your data and/or operational needs to me long-term.  It is my goal to build long-lasting relationships. I provide exceptional service at a price a large organization could never match.  Our partnership is of the greatest importance to me."
        />
        <Faq
          question="What services are included when partnering with you?"
          answer="Any data or operational support that your school may desire. This is not meant to be evasive, but I want 100% of resources and energy dedicated to your most pressing priorities. Most schools want meaningful data that helps aid teachers with instruction while also providing leadership with a clear understanding of trend performance (year-over-year and within the year).  This includes subgroup performance (grade, teacher, ethnicity, ELL, Special Education, FRL and other groups). They want multiple years of this data. 

          They also want a way to tie multiple data points for students together to provide an understanding of previous and future performance. Then show this performance at the student level so that teachers, students and parents/guardians have a clear understanding of current performance when meeting to discuss their child. 
          
          But your needs could differ and that is why we should connect. 
          "
        />
        <Faq
          question="Do you attend any meetings if needed?"
          answer="Absolutely. Whatever meeting time is required will certainly occur.
          "
        />
        <Faq
          question="What do you mean by operational support?"
          answer="Some schools need ways to tie data to daily operations. Mostly, this deals with attendance and discipline information. I can access pertinent data, provide data entry or coordinate scheduling. Other schools need bulk letter generations that include data for each student. I define operations as a fusion of data and coordination that provides the ability to communicate in some method.

          I even have contracts in the food-service portion of school systems collecting information around the creation and delivery of meals.
          "
        />
        <Faq
          question="Will you sign any waivers about student privacy?"
          answer="Absolutely. I do not share any data with anyone and will write that in the contract. Previously, I have had background checks conducted for employment in public schools, the federal government, and also by a school while running this business as a condition for our partnership."
        />
        <Faq
          question="What does the payment schedule look like?"
          answer="Most schools pay monthly, quarterly, or every six months. I NEVER take a dollar before services are completed. I even build an out in the contract if you are unhappy with the work performed. (This has never occurred)."
        />
        <Faq
          question="What is the cost?"
          answer="It depends on the scope of the work. I have contracts that range from $2,500 to $50,000. No contract is too big or small. The larger contracts have been built up over time with additional work added to subsequent contracts. In some cases, when a school employee leaves, leadership reworked contracts to reassign some of the outgoing employee’s work for a fraction of the cost to me."
        />
        <Faq
          question="How long are the contracts?"
          answer="I also leave this up to the school, but most contracts run for one calendar year. But I have some small-scale episodic contracts, too.  Whatever you are comfortable with works for me. "
        />
        <Faq
          question="I am interested. If I reach out, how long will it take for you to respond?"
          answer=" I will respond the same day using the same method you did. If you email, I will email back. If you text, I will text back. If you call, I will call you back if I miss your call. 
          "
        />
      </Box>
    </section>
  );
}

export default Box5;
const Faq = (props) => {
  const [more, setMore] = react.useState(false);
  const elementRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const isTextOverflowing = element.scrollWidth > element.clientWidth;
      setIsOverflowing(isTextOverflowing);
    }
  }, []);
  const manageMore = () => {
    if (more) {
      ReactGA.event({
        category: "FAQ",
        action: "clicked on faq expand to answer",
        label: `clicked ${props.question}`, // optional
        nonInteraction: false, // optional, true/false
      });
    }
    setMore(!more);
  };
  return (
    <>
      <Typography variant="h6" style={{ lineHeight: "31px" }}>
        {props.question}
        {isOverflowing && (
          <sub style={{ cursor: "pointer" }} onClick={manageMore}>
            {more ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </sub>
        )}
      </Typography>
      <Typography
        variant="h5"
        ref={elementRef}
        style={{
          textOverflow: more ? "" : "ellipsis",
          overflow: "hidden",
          whiteSpace: more ? "normal" : "nowrap",
        }}
      >
        {props.answer}
      </Typography>
      <hr style={{ color: "#333333", width: "100%", marginBottom: 50 }} />
    </>
  );
};
