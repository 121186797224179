import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "../home/homeStyle.css";
import { Outlet, useNavigate } from "react-router-dom";
import Box8 from "../home/box8";
import { theme } from "../App";

const useStyles = makeStyles({
  customSize: {
    "& .MuiButton-root": {
      // Add your desired width and height for the buttons

      marginBottom: "10px",
      [theme.breakpoints.down("md")]: {
        fontSize: 9,
      },
    },
  },
});

function Dashboard() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Box className="box1StyleDashboard">
        <Box className="dashboardContentContainer">
          <Typography
            variant="h1"
            style={{ marginBottom: "31px", lineHeight: "75px" }}
          >
            SCHOOL DATA BUFFET
          </Typography>
          <Typography
            variant="h4"
            style={{ marginBottom: "35px", lineHeight: "28px" }}
          >
            Providing Data and Operations Support Tailored For Your School
          </Typography>
          <ButtonGroup
            className={classes.customSize}
            size="medium"
            variant="text"
            color="secondary"
            aria-label="text primary button group"
          >
            <Button onClick={() => navigate("/dashboard/sheet1")}>
              Test Session Scores General Example
            </Button>
            <Button onClick={() => navigate("/dashboard/sheet4")}>
              Student Dashboard
            </Button>
            <Button onClick={() => navigate("/dashboard/sheet2")}>
              School Outreach
            </Button>
            <Button onClick={() => navigate("/dashboard/sheet3")}>
              Faculty Survey
            </Button>
            <Button onClick={() => navigate("/dashboard/demo")}>
              Video Discussion
            </Button>
          </ButtonGroup>

          <Outlet />
        </Box>
      </Box>
      <Box8 />
    </>
  );
}

export default Dashboard;
