import { Box, Button, TextField, Typography } from "@material-ui/core";
import react, { Suspense } from "react";
import profile from "../assets/Box6/1.png";
import { Loader } from "../components/loader";
const LazyLoadComponent = react.lazy(() => import("./frame"));
function Box6(props) {
  return (
    <Box className={props?.title ? "box6StyleVariant" : "box6Style"}>
      {props?.title && (
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          {props.title}
        </Typography>
      )}
      <Suspense fallback={<Loader />}>
        <LazyLoadComponent id={props.id} />
      </Suspense>
    </Box>
  );
}

export default Box6;
