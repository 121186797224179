import { Box, Button, TextField, Typography } from "@material-ui/core";
import "./homeStyle.css";
import react, { useEffect, useState } from "react";
import background from "../assets/background.mp4";
import { validEmail } from "../utils/validators";
function Box1() {
  const [email, setEmail] = useState("");
  let handleChange = (event) => {
    let value = event.target.value;
    setEmail(value);
  };
  let validateEmail = () => {
    if (validEmail(email)) {
      window.alert("Email submitted successfully, we'll contact you soon!");
      setEmail("");
    } else {
      if (email === "") {
        window.alert(`No email provided,please enter you email id`);
      } else {
        window.alert(`Incorrect email please check, ${email}`);
      }
    }
  };
  useEffect(() => {
    const elements = document.querySelectorAll(".contentContainer");

    const zoomInOnScroll = () => {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const position = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (position < windowHeight - windowHeight / 4) {
          element.classList.add("zoom-in-active");
        } else {
          element.classList.remove("zoom-in-active");
        }
      }
    };

    window.addEventListener("scroll", zoomInOnScroll);
    zoomInOnScroll();

    return () => {
      window.removeEventListener("scroll", zoomInOnScroll);
    };
  }, []);

  return (
    <section className="box1Style">
      <video autoPlay loop muted playsInline className="backVideo">
        <source src={background} type="video/mp4"></source>
        {/* <source
          src="https://cdn.dribbble.com/uploads/39421/original/963b4f8739cbdf86ca3f3a23245efd18.mp4?1657824985"
          type="video/mp4"
        ></source> */}
      </video>
      <Banner />
    </section>
  );
}

export default Box1;
export const Banner = ({ loader }) => {
  return (
    <Box className="contentContainer">
      <Typography variant="h1" className={loader ? "loader" : "box1Heading"}>
        SCHOOL DATA BUFFET
      </Typography>
      <Typography variant="h4" className={loader ? "loader" : "box1Heading1"}>
        Providing Data and Operations Support Tailored For Your School
      </Typography>
      {/* <div
  style={{
    flexDirection: "row",
    height: 55,
    width: "100%",
  }}
>
  <TextField
    id="outlined-basic"
    variant="outlined"
    placeholder="your email"
    style={{ width: "70%", height: "inherit" }}
    value={email}
    onChange={handleChange}
  />
  <Button
    variant="contained"
    color="secondary"
    style={{
      height: "inherit",
      width: "30%",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }}
    onClick={validateEmail}
  >
    get started
  </Button>
</div> */}
    </Box>
  );
};
