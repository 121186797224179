export const data = [
  [
    "values",
    "Pct High Achievement",
    "Winter School Average",
    "Fall 2022-2023 Pct",
  ],
  ["K", 60, 66, 78],
  ["1", 32, 66, 35],
  ["2", 63, 66, 58],
  ["3", 72, 66, 73],
  ["4", 65, 66, 73],
  ["5", 78, 66, 76],
  ["6", 82, 66, 75],
  ["7", 75, 66, 75],
  ["8", 67, 66, 65],
];
export const data2 = [
  [
    "values",
    "Pct High Achievement",
    "Winter School Average",
    "Fall 2022-2023 Pct",
  ],
  ["K", 73, 57, 73],
  ["1", 26, 57, 42],
  ["2", 62, 57, 57],
  ["3", 49, 57, 65],
  ["4", 71, 57, 68],
  ["5", 71, 57, 68],
  ["6", 53, 57, 55],
  ["7", 58, 57, 65],
  ["8", 52, 57, 47],
];
export const data3 = [
  ["Task", "Hours per Day"],
  ["High", 140],
  ["HiAvg", 108],
  ["Avg", 64],
  ["LoAvg", 55],
  ["Low", 65],
];
export const data4 = [
  ["Task", "Hours per Day"],
  ["High", 101],
  ["HiAvg", 103],
  ["Avg", 82],
  ["LoAvg", 67],
  ["Low", 81],
];
export const data5 = [
  [
    "values",
    "National Percent Average (Winter)",
    "Winter School Average",
    "Fall 2022-2023 Avg",
  ],
  ["K", 63, 59.35011188, 67.7],
  ["1", 35, 59.35011188, 41.35416667],
  ["2", 57, 59.35011188, 56.89583333],
  ["3", 61, 59.35011188, 65.28571429],
  ["4", 60, 59.35011188, 66.11363636],
  ["5", 66, 59.35011188, 68.14],
  ["6", 69, 59.35011188, 63.1554237],
  ["7", 67, 59.35011188, 66.61338468],
  ["8", 57, 59.35011188, 57.48837209],
];
export const data6 = [
  [
    "values",
    "National Percent Average (Winter)",
    "Winter School Average",
    "Fall 2022-2023 Avg",
  ],
  ["K", 70, 54.35634948, 71],
  ["1", 36, 54.35634948, 43],
  ["2", 55, 54.35634948, 53],
  ["3", 50, 54.35634948, 59],
  ["4", 58, 54.35634948, 61],
  ["5", 63, 54.35634948, 62],
  ["6", 49, 54.35634948, 51],
  ["7", 55, 54.35634948, 57],
  ["8", 52, 54.35634948, 49],
];
export const data7 = [
  ["Task", " "],
  ["Exceeded", 134],
  ["Met", 107],
  ["Nearly Met", 56],
  ["Not Met", 48],
];
export const data8 = [
  ["Task", " "],
  ["Exceeded", 84],
  ["Met", 98],
  ["Nearly Met", 87],
  ["Not Met", 79],
];
export const data9 = [
  ["values", "K", "1st Grade"],
  ["Foundational Skills", 61, 36],
  ["Language and Writing", 58, 35],
  ["Literature and Informational", 64, 33],
  ["Vocabulary Use and Functions", 68, 37],
];
export const data10 = [
  [
    "values",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
  ],
  ["Literary Text: Key Ideas and Details", 55, 60, 53, 62, 68, 68, 57],
  ["Literary Text: Language, Craft, and Structure", 57, 60, 60, 63, 68, 65, 62],
  ["Informational Text: Key Ideas and Details", 58, 62, 66, 69, 69, 69, 67],
];
export const data11 = [
  [
    "values",
    "K",
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
  ],
  ["Operations and Algebraic Thinking", 64, 43, 53, 53, 61, 65],
  ["Number and Operations", 69, 33, 58, 43, 55, 72],
  ["Measurement and Data", 68, 30, 53, 48, 56, 60],
  ["Geometry", 72, 39, 57, 59, 60, 49],
];
export const data12 = [
  ["values", "6th Grade", "7th Grade", "8th Grade"],
  ["Operations and Algebraic Thinking (M)", 45, 52, 54],
  ["The Real and Complex Number Systems", 56, 58, 56],
  ["Geometry and Measurement", 50, 56, 52],
  ["Statistics and Probability", 45, 53, 52],
];
export const data13 = [
  ["Task", "Hours per Day"],
  ["Foundational Skills", 9],
  ["Language and Writing", 10],
  ["Vocabulary Use and Functions", 8],
  ["Literature and Informational", 9],
  ["Critical", 11],
];
export const data14 = [
  ["Task", "Hours per Day"],
  ["Literature", 118],
  ["Informational Text", 112],
  ["Vocabulary Acquision and Use", 81],
  ["Critical", 28],
  ["Perfect", 6],
];
export const data15 = [
  ["Task", "Hours per Day"],
  ["Operations and Algebraic Thinking", 26],
  ["Measurement and Data", 54],
  ["Geometry", 57],
  ["Number and Operations", 62],
  ["Critical", 26],
  ["Perfect", 6],
];
export const data16 = [
  ["Task", "Hours per Day"],
  ["Operations and Algebraic Thinking", 41],
  ["The Real and Complex Number Systems", 19],
  ["Geometry and Measurement", 29],
  ["Statistics and Probability", 39],
  ["Critical", 29],
];
export const data17 = [
  [
    "values",
    "Winter 2022-2023",
    "Fall 2022-2023",
    "Spring 2021-2022",
    "Fall 2021-2022",
  ],
  ["Cohort 2030 (1st)", 32, 35, 58, 64],
  ["Cohort 2029 (2nd)", 63, 58, 64, 74],
  ["Cohort 2028 (3rd)", 72, 73, 81, 83],
  ["Cohort 2027 (4th)", 65, 69, 72, 73],
  ["Cohort 2026 (5th)", 78, 76, 79, 83],
  ["Cohort 2025 (6th)", 82, 75, 78, 84],
  ["Cohort 2024 (7th)", 75, 68, 70, 75],
  ["Cohort 2023 (8th)", 67, 61, 65, 65],
];
export const data18 = [
  [
    "values",
    "Winter 2022-2023",
    "Fall 2022-2023",
    "Spring 2021-2022",
    "Fall 2021-2022",
  ],
  ["Cohort 2030 (1st)", 26, 42, 59, 75],
  ["Cohort 2029 (2nd)", 62, 57, 72, 73],
  ["Cohort 2028 (3rd)", 49, 65, 79, 92],
  ["Cohort 2027 (4th)", 71, 60, 68, 74],
  ["Cohort 2026 (5th)", 71, 68, 71, 73],
  ["Cohort 2025 (6th)", 53, 55, 60, 73],
  ["Cohort 2024 (7th)", 58, 55, 59, 65],
  ["Cohort 2023 (8th)", 52, 45, 47, 50],
];
export const data19 = [
  ["Task", "Hours per Day"],
  ["Yes", 196],
  ["Progress", 84],
  ["No", 143],
];
export const data20 = [
  ["Task", "Hours per Day"],
  ["Yes", 205],
  ["Progress", 95],
  ["No", 128],
];
export const data21 = [
  ["values", "Yes", "Progress", ""],
  ["K", 56.41, 12.82, 30.77],
  ["1st Grade", 25.53, 21.28, 53.19],
  ["2nd Grade", 45.65, 26.09, 28.26],
  ["3rd Grade", 34.04, 27.66, 38.3],
  ["4th Grade", 27.91, 18.6, 53.49],
  ["5th Grade", 46.94, 16.33, 36.73],
  ["6th Grade", 58.93, 21.43, 19.64],
  ["7th Grade", 64.71, 15.69, 19.6],
  ["8th Grade", 53.33, 17.78, 28.89],
];
export const data22 = [
  ["values", "Yes", "Progress", ""],
  ["K", 53.85, 30.77, 15.38],
  ["1st Grade", 23.91, 28.26, 47.83],
  ["2nd Grade", 53.33, 26.67, 20.0],
  ["3rd Grade", 31.91, 25.53, 42.55],
  ["4th Grade", 35.56, 20.0, 44.44],
  ["5th Grade", 59.18, 14.29, 26.53],
  ["6th Grade", 50.88, 19.3, 29.82],
  ["7th Grade", 53.85, 13.46, 32.69],
  ["8th Grade", 66.67, 25.0, 8.33],
];
export const data23 = [
  ["values", ""],
  ["All Students", 59.61574074],
  ["White", 70.71794872],
  ["Hispanic", 45.14556962],
  ["Asian", 70.08333333],
  ["Black or African American", 48.57692308],
  ["Two or More Races", 61.21428571],
];
export const data24 = [
  ["values", ""],
  ["All Students", 54.07834101],
  ["White", 65.63291139],
  ["Hispanic", 40.03773585],
  ["Asian", 66.15277778],
  ["Black or African American", 30.8],
  ["Two or More Races", 58],
];
export const data25 = [
  ["Task", " "],
  ["Yes", 15],
  ["Progress", 4],
  ["No", 7],
];
export const data26 = [
  ["Task", " "],
  ["Yes", 39],
  ["Progress", 15],
  ["No", 44],
];
export const data27 = [
  ["Task", " "],
  ["Yes", 15],
  ["Progress", 8],
  ["No", 5],
];
export const data28 = [
  ["Task", " "],
  ["Yes", 41],
  ["Progress", 19],
  ["No", 38],
];
export const data29 = [
  ["values", "General Education", "Special Education"],
  ["Reading", 61, 44],
  ["Math", 55, 39],
];
export const data30 = [
  ["values", "Non FRL", "FRL"],
  ["Reading", 66, 38],
  ["Math", 62, 30],
];
export const data31 = [
  ["Task", " "],
  ["Exceeded", 21],
  ["Met", 41],
  ["Nearly Met", 29],
  ["Not Met", 31],
];
export const data32 = [
  ["Task", " "],
  ["Exceeded", 38],
  ["Met", 42],
  ["Nearly Met", 21],
  ["Not Met", 21],
];
export const data33 = [
  ["Task", " "],
  ["Exceeded", 6],
  ["Met", 8],
  ["Nearly Met", 3],
  ["Not Met", 6],
];
export const data34 = [
  ["Task", ""],
  ["Exceeded", 5],
  ["Met", 8],
  ["Nearly Met", 3],
  ["Not Met", 4],
];
export const data35 = [
  ["Task", " "],
  ["Exceeded", 4],
  ["Met", 9],
  ["Nearly Met", 8],
  ["Not Met", 7],
];
export const data36 = [
  ["Task", " "],
  ["Exceeded", 3],
  ["Met", 6],
  ["Nearly Met", 4],
  ["Not Met", 15],
];
export const data37 = [
  ["Task", " "],
  ["Exceeded", 12],
  ["Met", 22],
  ["Nearly Met", 22],
  ["Not Met", 6],
];
export const data38 = [
  ["Task", ""],
  ["Exceeded", 4],
  ["Met", 12],
  ["Nearly Met", 19],
  ["Not Met", 47],
];
export const options = {
  title: "Percent High Achieving By Grade: Reading",
  chartArea: { width: "80%", height: "70%" },
  legend: {
    position: "none",
    textStyle: { fontSize: 10 },
  },
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  colors: ["#5C85D6", "#E36141", "#FFAD33"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  pointSize: 5,
  seriesType: "bars",
  series: { 1: { type: "line" }, 2: { type: "line" } },
};
export const options2 = {
  title: "Percent High Achieving By Grade: Math",
  chartArea: { width: "80%", height: "70%" },

  legend: {
    position: "none",
    textStyle: { fontSize: 10 },
    maxLines: 1,
    alignment: "center",
  },

  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  colors: ["#5C85D6", "#E36141", "#FFAD33"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  pointSize: 5,
  seriesType: "bars",
  series: { 1: { type: "line" }, 2: { type: "line" } },
};

export const options3 = {
  title: "Winter Quintile Performance: Reading",
  chartArea: { width: "90%", height: "70%" },
  is3D: false,
  animation: {
    duration: 3000,
    easing: "out",
    startup: true,
  },
};
export const options4 = {
  title: "Winter Quintile Performance: Math",
  chartArea: { width: "90%", height: "70%" },
  is3D: false,
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
};
export const options5 = {
  title: "Percent High Achieving By Grade: Reading",
  legend: { position: "none" },
  chartArea: { width: "80%", height: "70%" },
  colors: ["#5C85D6", "#E36141", "#FFAD33"],
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  // colors: ["yellow", "red", "green"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  pointSize: 5,
  seriesType: "bars",
  series: {
    1: {
      pointShape: { type: "star", sides: 4, dent: 0 },
      type: "line",
      lineDashStyle: [2, 4],
    },
    2: { type: "line" },
  },
};
export const options6 = {
  title: "Percent High Achieving By Grade: Math",
  legend: { position: "none" },
  chartArea: { width: "80%", height: "70%" },
  colors: ["#5C85D6", "#E36141", "#FFAD33"],
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },

  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  pointSize: 5,
  seriesType: "bars",
  series: {
    1: {
      pointShape: { type: "star", sides: 4, dent: 0 },
      type: "line",
      lineDashStyle: [2, 4],
    },
    2: { type: "line" },
  },
};
export const options7 = {
  title: "Projection on State Assessment: Reading",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options8 = {
  title: "Projection on State Assessment: Math",
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  pieHole: 0.4,
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options9 = {
  title: "Strand Analysis: Reading Grades K-1",
  legend: { position: "none" },
  chartArea: { width: "80%", height: "70%" },
  colors: ["#5C85D6", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  pointSize: 5,
  seriesType: "bars",
  series: "bars",
};
export const options10 = {
  title: "Strand Analysis: Reading Grades 2-8",
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  legend: { position: "none" },
  chartArea: { width: "80%", height: "70%" },
  colors: [
    "#5C85D6",
    "#E36141",
    "#FFAD33",
    "#33B133",
    "#B933B1",
    "#33ADD6",
    "#F14D8F",
  ],
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  pointSize: 5,
  seriesType: "bars",
};
export const options11 = {
  title: "Strand Analysis: Math Grades K-5",
  legend: { position: "none" },
  chartArea: { width: "80%", height: "70%" },
  colors: ["#5C85D6", "#E36141", "#FFAD33", "#33B133", "#B933B1", "#33ADD6"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  // colors: ["yellow", "red", "green", "blue", "purple", "orange", "pink"],
  pointSize: 5,
  seriesType: "bars",
};
export const options12 = {
  title: "Strand Analysis: Math Grades 6-8",
  legend: { position: "none" },
  chartArea: { width: "80%", height: "70%" },
  colors: ["#5C85D6", "#E36141", "#FFAD33"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  // colors: ["yellow", "red", "green", "blue", "purple", "orange", "pink"],
  pointSize: 5,
  seriesType: "bars",
};
export const options13 = {
  title: "Poorest Reading strand Grades K-1",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options14 = {
  title: "Poorest Reading strand Grades 2-8",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options15 = {
  title: "Poorest Math strand Grades K-5",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options16 = {
  title: "Poorest Math strand Grades 6-8",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options17 = {
  title: "Percentage of Students 50th Percentile or Better by Cohort: Reading",
  legend: { position: "none" },
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  colors: ["#5C85D6", "#E36141", "#FFAD33", "#33B133"],
  pointSize: 5,
  seriesType: "bars",
  series: { 1: { type: "line" }, 2: { type: "line" }, 3: { type: "line" } },
};
export const options18 = {
  title: "Percentage of Students 50th Percentile or Better by Cohort: Math",
  legend: { position: "none" },
  colors: ["#5C85D6", "#E36141", "#FFAD33", "#33B133"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  // colors: ["yellow", "red"],
  pointSize: 5,
  seriesType: "bars",
  series: { 1: { type: "line" }, 2: { type: "line" }, 3: { type: "line" } },
};
export const options19 = {
  title: "Fall to Winter Growth Reading: School",
  chartArea: { width: "90%", height: "70%" },
  colors: ["#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  pieHole: 0.4,
  is3D: false,
};
export const options20 = {
  title: "Fall to Winter Growth Math: School",
  chartArea: { width: "90%", height: "70%" },
  colors: ["#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  pieHole: 0.4,
  is3D: false,
};
export const options21 = {
  title: "Fall to Winter Growth: Reading",
  legend: { position: "top" },
  colors: ["#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  isStacked: true,
  hAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
};
export const options22 = {
  title: "Fall to Winter Growth: Math",
  colors: ["#5C85D6", "#FFAD33", "#E36141"],
  legend: { position: "top" },
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  isStacked: true,
  hAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
};
export const options23 = {
  title: "Average National Percentile by Education Status: Reading ",
  legend: "none",
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  hAxis: { minValue: 0, maxValue: 80 },
};
export const options24 = {
  title: "Average National Percentile by Education Status: Math",
  legend: "none",
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  hAxis: { minValue: 0, maxValue: 80 },
};
export const options25 = {
  title: "Growth Status: Reading Special Education",
  is3D: true, // yes blue, progress orange, no red
  chartArea: { width: "90%", height: "70%" },
  colors: ["#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
};
export const options26 = {
  title: "Growth Status: Reading FRL",
  is3D: true,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
};
export const options27 = {
  title: "Growth Status: Math Special Education",
  is3D: true,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
};
export const options28 = {
  title: "Growth Status: Math FRL",
  is3D: true,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
};
export const options29 = {
  title: "Performance General Education vs Special Education",
  legend: "none",
  colors: ["#008080", "#FFC0CB"],
  legend: { position: "bottom" },
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  hAxis: { minValue: 0, maxValue: 75 },
};
export const options30 = {
  title: "Performance Non-FRL vs. FRL ",
  legend: { position: "bottom" },
  colors: ["#008080", "#FFC0CB"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  hAxis: { minValue: 0, maxValue: 75 },
};
export const options31 = {
  title: "Projection on State Assessment: Hispanic/Latino Students: Reading",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options32 = {
  title: "Projection on State Assessment: Hispanic/Latino Students: Math",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options33 = {
  title:
    "Projection on State Assessment: Black or African American Students: Reading",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options34 = {
  title:
    "Projection on State Assessment: Black or African American Students: Math",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options35 = {
  title: "Projection on State Assessment: IEP Students: Reading",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options36 = {
  title: "Projection on State Assessment: IEP Students: Math",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options37 = {
  title: "Projection on State Assessment: FRL Students: Reading",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
export const options38 = {
  title: "Projection on State Assessment: FRL Students: Math",
  pieHole: 0.4,
  chartArea: { width: "90%", height: "70%" },
  colors: ["#33B133", "#5C85D6", "#FFAD33", "#E36141"],
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  is3D: false,
};
