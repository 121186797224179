import "../home/homeStyle.css";
import { Chart } from "react-google-charts";
import { Typography } from "@material-ui/core";
import { tableConsts } from "./sheet2";
import { Diversity2Outlined } from "@mui/icons-material";
import { dataThird1Legend } from "./sheetData";
import { Loader } from "../components/loader";
import { useEffect } from "react";
import ReactGA from "react-ga4";
export const dataThird1 = [
  [
    "values",
    "Spring 21-22 Group Avg",
    "Spring 20-21 School Avg",
    "Fall 20-21 Group Avg",
    "Fall 21-22 Group Avg",
    "Spring 19-20 School Avg",
  ],
  ["Senior Leadership", 3.67, 3.52, 3.7, 3.88, 4.2],
  ["Operations Leadership", 3.75, 3.52, 3.7, 3.88, 4.2],
  ["Emerging Leaders/Non-Classroom", 3.75, 3.52, 3.7, 3.88, 4.2],
  ["Elementary School Teacher Leaders", 4.6, 3.52, 3.7, 3.88, 4.2],
  ["Middle School Teacher Leaders", 4.08, 3.52, 3.7, 3.88, 4.2],
  ["Elementary School Teachers", 3.43, 3.52, 3.7, 3.88, 4.2],
  ["Middle School Teachers", 3.88, 3.52, 3.7, 3.88, 4.2],
  ["Support Staff", 3.5, 3.52, 3.7, 3.88, 4.2],
  ["Hourly Workers", 4.5, 3.52, 3.7, 3.88, 4.2],
];
export const optionsThird1 = {
  title: "At work, I have the opportunity to make an impact. ",
  legend: { position: "none" },
  animation: {
    duration: 3000,
    easing: "linear",
    startup: true,
  },
  vAxis: { minValue: 0, maxValue: 5 },
  hAxis: {
    slantedText: true,
  },
  // colors: ["yellow", "red", "green"],
  pointSize: 5,
  chartArea: { width: "80%", height: "50%" },
  seriesType: "bars",
  series: {
    1: {
      pointShape: { type: "circle", sides: 0, dent: 0 },
      type: "line",
      lineDashStyle: [1, 1],
    },
    2: {
      pointShape: { type: "triangle", sides: 4, dent: 0 },
      type: "line",
      lineDashStyle: [1, 1],
    },
    3: {
      pointShape: { type: "rectangle", sides: 4, dent: 0 },
      type: "line",
      lineDashStyle: [1, 1],
    },
    4: {
      pointShape: { type: "square", sides: 4, dent: 0 },
      type: "line",
      lineDashStyle: [1, 1],
    },
  },
};

function Sheet3() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Faculty Survey",
    });
    console.log(
      "location 3",
      window.location.pathname + window.location.search
    );
  }, []);
  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 600 }}>
        Faculty Survey
      </Typography>
      <div className="sheet3">
        <div>
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="600px"
            loader={<Loader />}
            data={dataThird1}
            options={optionsThird1}
          />

          <Legend legends={dataThird1Legend} />
        </div>
        <div className="annotationTables">
          <table className="tableBorder">
            <tr className="boldText sheet3header">
              <th>Spring 21-22 Responses</th>
              <th>Pct</th>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.five}</td>
              <td>31.81%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.four}</td>
              <td>36.50%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.three}</td>
              <td>15.05%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.two}</td>
              <td>16.63%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.one}</td>
              <td>0.00%</td>
            </tr>
          </table>
          <table className="tableBorder">
            <tr className="sheet3header">
              <th>Spring 21-22 Responses</th>
              <th>Pct</th>
            </tr>

            <tr className="boldText">
              <td>4 & 5 Percentage</td>
              <td className="boldText">68.31%</td>
            </tr>
            <tr className="boldText">
              <td>3 Percentage</td>
              <td className="boldText">15.05%</td>
            </tr>
            <tr className="boldText">
              <td>1 & 2 Percentage</td>
              <td className="boldText">16.63%</td>
            </tr>
          </table>
          <table className="tableBorder">
            <tr className="sheet3header">
              <th>Ranking For This Question</th>
              <th>Avg</th>
            </tr>
            <tr className="boldText">
              <td className="sheet3-4">Fall 21-22 School Avg</td>
              <td className="boldText">3.52</td>
            </tr>
            <tr className="boldText">
              <td className="sheet3-3">Spring 20-21 School Avg</td>
              <td className="boldText">3.68</td>
            </tr>
            <tr className="boldText">
              <td className="sheet3-2">Fall 20-21 School Avg</td>
              <td className="boldText">3.88</td>
            </tr>
            <tr className="boldText">
              <td className="sheet3-1">Spring 19-20 School Avg</td>
              <td className="boldText">4.20</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="sheet3otherTables">
        <table className="tableBorder ">
          <tr className="sheet3header">
            <th>Ranking Groups Responses to This Question</th>
            <th>Avg</th>
          </tr>
          <tr>
            <td>Elementary School Teacher Leaders</td>
            <td>4.60</td>
          </tr>
          <tr>
            <td>Hourly Workers</td>
            <td>4.50</td>
          </tr>
          <tr>
            <td>Middle School Teacher Leaders</td>
            <td>4.08</td>
          </tr>
          <tr>
            <td>Middle School Teachers</td>
            <td>3.88</td>
          </tr>
          <tr>
            <td>Operations Leadership</td>
            <td>3.75</td>
          </tr>
          <tr>
            <td>Emerging Leaders/Non-Classroom</td>
            <td>3.75</td>
          </tr>
          <tr>
            <td>Senior Leadership</td>
            <td>3.67</td>
          </tr>
          <tr>
            <td>Support Staff</td>
            <td>3.50</td>
          </tr>
          <tr>
            <td>Elementary School Teachers</td>
            <td>3.43</td>
          </tr>
        </table>
        <table className="tableBorder">
          <tr className="sheet3header">
            <th>Winter 22-23 Ranking For This Question (out of 13)</th>
            <th>Rank</th>
          </tr>
          <tr>
            <td>Senior Leadership</td>
            <td className="boldText">6</td>
          </tr>
          <tr>
            <td>Operations Leadership</td>
            <td className="boldText">5</td>
          </tr>
          <tr>
            <td>Emerging Leaders/Non-Classroom</td>
            <td className="boldText">7</td>
          </tr>
          <tr>
            <td>Elementary School Teacher Leaders</td>
            <td className="boldText">1</td>
          </tr>
          <tr>
            <td>Middle School Teacher Leaders</td>
            <td className="boldText">9</td>
          </tr>
          <tr>
            <td>Elementary School Teachers</td>
            <td className="boldText">9</td>
          </tr>

          <tr>
            <td>Middle School Teachers</td>
            <td className="boldText">6</td>
          </tr>
          <tr>
            <td>Support Staff</td>
            <td className="boldText">10</td>
          </tr>
          <tr>
            <td>Hourly Workers</td>
            <td className="boldText">1</td>
          </tr>
        </table>
      </div>
    </>
  );
}

export default Sheet3;

export const Legend = ({ legends }) => {
  return (
    <div className="legendStyle">
      {legends.map((legend, index) => {
        return (
          <div
            key={`${legend}-index${index}`}
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 5,
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "inline-block",
                height: "15px",
                width: "20px",
                backgroundColor: legend?.color,
                margin: 3,
              }}
            ></span>
            <span style={{ fontSize: 14, fontWeight: 300 }}>
              {legend?.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};
