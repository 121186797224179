export const content1 = {
  heading: "Schoolwide Analysis",
  body: "All data collected throughout the year (and previous years) is collected and analyzed to identify performance, gaps and trends. The more assessment and data we have, the more focused our understanding of performance will be. This type of analysis answers questions including:",
  points: [
    "How is the school performing against previous years?",
    "To what extent has there been improvement during the school year?",
    "How will our students perform on a future assessment? Future state assessment?",
    "What groups/grades/content areas/teachers have demonstrated elevated performance?",
    "What groups/grades/content areas/teachers have demonstrated underperformance?",
    "What instructional areas have been mastered?",
    "What instructional areas have underperformed?",
  ],
};
export const content2 = {
  heading: "Student Tracking",
  body: "This type of data will be a great form of communication between parent, teacher and student. School loves this data for parent/teacher conferences. This can track all assessments that a student has ever taken while enrolled at the school. Feedback from educators and goal-setting can be included.",
};
export const content3 = {
  heading: "Logistical Support Services",
  body: "This domain of work can vary greatly from one location to another. Surveying educators, parents and stakeholders is one common request. For some schools, surveying takes place multiple times a year to provide a greater understanding of current academic, social, and professional school conditions. All of this information updates in real-time so school leaders have an immediate understanding of current beliefs. Some schools request support with attendance and discipline data.  This includes letters for habitual tardiness and absences. By grouping your academic and attendance needs together, data can seamlessly be coordinated to include both metrics in any academic dashboards or reports. I also have schools who request assistance with food service data support. Since COVID, I have coordinated with schools to account for all meals that have been created, delivered and provided for students. At the height of the COVID response, over 500K meals were provided by one client and all the data around this was updated in real time for school officials.  Other requested services include report cards, data entry, and master scheduling and automated letter generated-services for communication.",
};
export const content4 = {
  heading: "Standardized Testing Preparation and Analysis",
  body: "Students who are preparing for aptitude testing often need preparation materials that can be graded in real-time that show individual student data. This can be used for baseline assessments at any point in the school year or as students prepare for exams at different junctures in the educational journey. This includes SAT/ACT preparation at the high school level.  These types of tests measure more than ability: they also measure agility and endurance throughout the exam. These are skills that can be refined with resources that are available for students whenever it is convenient for them. ",
};

export const box7Content = {
  heading: "Let's Connect",
  body: "Reach out and let's see how I can help",
};
