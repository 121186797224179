import { Box, Button, TextField, Typography } from "@material-ui/core";
import Box8 from "../home/box8";
import "../contactUs/contact.css";
import { ContactFormComponent, Header } from "../contactUs/contactUs";
import img1 from "../../src/assets/about/1.png";
import img2 from "../../src/assets/about/2.png";
import img3 from "../../src/assets/about/3.png";
import img3a from "../../src/assets/about/3a.png";
import img4 from "../../src/assets/about/4.png";
import img5 from "../../src/assets/about/5.png";
import Box7 from "../home/box7";
import Box2 from "../home/box2";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const mySelfTitle = "About Me";
const mySelfBody = `Before starting this business, I was a teacher and school administrator in Fairfax County Public Schools. I also worked two years as a teacher in Broward County Public Schools in Florida.  In total, I was a classroom teacher for seven years and a school administrator for three.  I still possess licenses as a secondary classroom teacher and K-12 administrator in Virginia.\n\nCurrently, I also operate an educational center in Northern Virginia for students elementary through high school with roughly 150-200 kids enrolled.\n\n I earned my PhD and a Master’s Degree from George Mason University in Education. Additionally, I hold an MA from Ball State University and BS from Florida State University.\n\n I reside in Ashburn, Virginia with my wife and two kids.`;

export const Demo = ({ loader }) => {
  return (
    <div className={loader ? "" : "responsive"}>
      <div className="imgA1">
        <img src={img1} className="imageA1" />
      </div>
      <div className="imgA2">
        <img src={img2} className="imageA2" />
      </div>
      <div className="imgA3">
        <img src={img3} className="imageA3" />
      </div>
      <div className="imgA3-1a">
        <img src={img3a} className="imageA3-1a" />
      </div>
      <div className="imgA4">
        <img src={img4} className="imageA4" />
      </div>
      <div className="imgA4-2">
        <img src={img4} className="imageA4" />
      </div>
      <div className="imgA5">
        <img src={img5} className="imageA5" />
      </div>
      <div className="imgA6">
        <img src={img5} className="imageA6" />
      </div>
      <div className="imgA4-1a">
        <img src={img4} className="imageA4-1a" />
      </div>
      <div className="imgA4-1b">
        <img src={img4} className="imageA4-1a" />
      </div>
      <div className="imgA4-2a">
        <img src={img4} className="imageA4-2a" />
      </div>
      <div className="imgA4-2b">
        <img src={img4} className="imageA4-2a" />
      </div>
      <div className="imgA4-3a">
        <img src={img4} className="imageA4-3a" />
      </div>
      <div className="imgA4-3b">
        <img src={img4} className="imageA4-3a" />
      </div>
    </div>
  );
};
export const Demo2 = ({ loader }) => {
  return (
    <div className={loader ? "" : "responsive-2"}>
      <img src={img1} className="imageB1-2" />
      <div className="imgB2-2">
        <img src={img2} className="imageB2-2" />
      </div>
      <div className="imgB3-2">
        <img src={img3} className="imageB3-2" />
      </div>
      <div className="imgB3-1a-2">
        <img src={img3a} className="imageB3-1a-2" />
      </div>
      <div className="imgB4-2-2">
        <img src={img4} className="imageB4-2" />
      </div>
      <div className="imgB4-2-2">
        <img src={img4} className="imageB4-2" />
      </div>
      <div className="imgB5-2">
        <img src={img5} className="imageB5-2" />
      </div>
      <div className="imgB6-2">
        <img src={img5} className="imageB6-2" />
      </div>
      <div className="imgB4-1a-2">
        <img src={img4} className="imageB4-1a-2" />
      </div>
      <div className="imgB4-1b-2">
        <img src={img4} className="imageB4-1a-2" />
      </div>
      <div className="imgB4-2a-2">
        <img src={img4} className="imageB4-2a-2" />
      </div>
      <div className="imgB4-2b-2">
        <img src={img4} className="imageB4-2a-2" />
      </div>
      <div className="imgB4-3a-2">
        <img src={img4} className="imageB4-3a-2" />
      </div>
      <div className="imgB4-3b-2">
        <img src={img4} className="imageB4-3a-2" />
      </div>
    </div>
  );
};

function About() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "About us page",
    });
  }, []);
  return (
    <Box className="contactPageContainer">
      <Header heading="About Us" page="About" />
      <AboutBusiness />
      <Box2 title={mySelfTitle} body={mySelfBody} />
      <Box7 />
      <section className="letsTalkContainer">
        <div className="contactForm">
          <Typography
            variant="h5"
            color="secondary"
            style={{ alignSelf: "center", fontWeight: 600 }}
          >
            LET'S TALK
          </Typography>
          {/* <Typography variant="h6" className="contactFormHeader">
            Request For Proposal
          </Typography> */}

          <ContactFormComponent text="SUBMIT NOW" />
          <br />
          {/* <ContactFormComponent text="SUBMIT NOW" disabled={false} /> */}
        </div>
      </section>
      <Box8 />
    </Box>
  );
}
export const AboutBusiness = () => {
  return (
    <div className="cards">
      <Demo />
      <Box className="profileContent">
        <Typography
          variant="h4"
          style={{
            color: "#F26723",
            textTransform: "uppercase",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          ABOUT SCHOOL DATA BUFFET
        </Typography>
        <Typography
          variant="h2"
          style={{
            lineHeight: "52px",
            marginBottom: "21px",
            textAlign: "center",
          }}
        >
          Data analysis & consulting
        </Typography>
        <BusinessDetails />
      </Box>
    </div>
  );
};

export default About;

export const BusinessDetails = () => {
  return (
    <section id="section1">
      <Typography variant="h5" className="aboutDetail">
        Currently, I am providing data and operational support for schools in
        California, Texas, Michigan, Virginia, North Carolina, Indiana, Idaho,
        New York, Louisiana, Missouri and Washington, DC. The data and
        operations support that I provide for schools varies in scope and depth.
        I work within the financial and operational parameters of each school.
        The purpose of this website is simple: to demonstrate that data and
        operational support is not only possible, but affordable and tailored
        fully to the needs of your school. There are countless demands and
        responsibilities required of educators each day. Additionally, at some
        time, schools experience change and turnover. My service is a safeguard
        against all these commitments and changes. While educators come and go,
        the data and support required for your school remains on schedule and
        effectively completed. Among the services that have been completed to
        date, here are some of the most desired requests:
        <ul
          style={{
            overflow: "hidden",
            alignSelf: "center",
            textAlign: "initial",
          }}
        >
          <li>State Testing Analysis</li>
          <li>
            State Testing Projection Analysis (Using Several Assessments to
            Project Future Testing)
          </li>
          <li>
            Corresponding Assessment Analysis (MAP, iReady, Renaissance and
            Others)
          </li>
          <li>
            Creation of Report Cards and Other Types of Letters to Families
          </li>
          <li>
            Attendance Management and Analysis (Tying in With Academic Metrics
            and Performance)
          </li>
          <li> Assessment Creation, Evaluation and Analysis </li>
          <li>
            Survey Creation and Analysis For Educators and the School Community
          </li>
          <li>
            Student Dashboards (Pulling Several Different
            Assessment/Demographic/Discipline.Attendance Data Points into One
            Sheet for Meetings With Students and Parents)
          </li>
          <li>Credit Analysis For Graduation</li>
          <li> Powerschool (or other SIS) Support</li>
          <li>Attendance at Leadership, Instructional and/or Team Meetings</li>
        </ul>
      </Typography>
    </section>
  );
};

const Card1 = (props) => {
  return (
    <Box className="contactCard">
      <Typography
        variant="h3"
        style={{
          lineHeight: "29px",
          textAlign: "center",
          marginBottom: "5px",
          fontSize: "30px",
        }}
      >
        {props?.head}
      </Typography>
      <Typography variant="h5">{props?.content}</Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" style={{ color: "#212121" }}>
          James Triolo
        </Typography>
        <Typography variant="h5">XYZ, Xaverian High School</Typography>
      </div>
    </Box>
  );
};
