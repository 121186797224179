import { Typography } from "@material-ui/core";
import {
  sheet4Table0,
  sheet4Table1,
  sheet4Table10,
  sheet4Table2,
  sheet4Table3,
  sheet4Table4,
  sheet4Table6,
  sheet4Table7,
  sheet4Table8,
  sheet4Table9,
} from "./tables";
import ReactGA from "react-ga4";
import { Loader } from "../components/loader";
import { useEffect } from "react";
function Sheet4() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Student Dashboard",
    });
  }, []);
  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 600 }}>
        Student Dashboard
      </Typography>
      {/* <Loader /> */}
      <div className="sheet1">
        <div
          style={{
            backgroundColor: "rgba(255,191,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6"> YOUR SCHOOL </Typography>
        </div>
        <Table rows={sheet4Table0} caption="" color="none" />
      </div>
      <div className="sheet1">
        <Table
          // background
          rows={sheet4Table1}
          caption="Math - IReady or MAP or Any Other Assessment"
          color="rgba(207,226,243,1)"
        />
        <Table
          // background
          rows={sheet4Table2}
          caption="Reading- IReady or MAP or Any Other Assessment"
          color="rgba(182,215,168,1)"
        />
        <Table
          // background
          rows={sheet4Table3}
          caption="Math - State Exam Data"
          color="rgba(207,226,243,1)"
        />
        <Table
          // background
          rows={sheet4Table4}
          caption="Reading - State Exam Data"
          color="rgba(182,215,168,1)"
        />

        <Table2 color="rgba(234,209,220,1)" caption="WIDA" />
        <Table
          // background
          rows={sheet4Table6}
          caption="iReady Instruction"
          color="rgba(217,210,233,1)"
        />
      </div>
      <div className="table710">
        <Table
          rows={sheet4Table10}
          background={true}
          caption="Strand Key"
          color="rgba(207,226,243,1)"
        />
      </div>
      {/* <iframe
        className="StudentDashboard"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQEH8FeMLRr1DCtLZPHSJmRC3hsNuodT7eRH1iLQoEwJ6qVq8akEukvxN6Zq2xkQ0t4MIU8cDhjuJcy/pubhtml?widget=true&amp;&amp;chrome=false&amp;headers=false"
      ></iframe> */}
    </>
  );
}

export default Sheet4;

const Table2 = ({ color, caption }) => {
  return (
    <div className="table2">
      <table className={"tableBorder"}>
        <caption style={{ backgroundColor: color }}>{caption}</caption>

        <tr>
          <th>Category</th>
          <th>SY 22-23</th>
          <th>SY 21-22</th>
          <th>SY 20-21</th>
          <th>SY 19-20</th>
        </tr>

        <tr>
          <th>Overall</th>
          <td>3.7</td>
          <td>3.8</td>
          <td>3.9</td>
          <td>3.9</td>
        </tr>

        <tr>
          <th>Listening</th>
          <td>4.5</td>
          <td>6</td>
          <td>3.2</td>
          <td>3.2</td>
        </tr>

        <tr>
          <th>Speaking</th>
          <td>4.4</td>
          <td>4</td>
          <td>4.2</td>
          <td>4.2</td>
        </tr>

        <tr>
          <th>Reading</th>
          <td>3</td>
          <td>3.1</td>
          <td>4.2</td>
          <td>4.2</td>
        </tr>

        <tr>
          <th>Writing</th>
          <td>3.6</td>
          <td>3.3</td>
          <td>3.2</td>
          <td>3.2</td>
        </tr>

        <tr>
          <th>Screening Date</th>
          <td colSpan={4}>9/10/2021</td>
        </tr>

        <tr>
          <th>Language</th>
          <td colSpan={4}>Spanish</td>
        </tr>
      </table>
    </div>
  );
};

const Table = ({ rows, caption, color, background }) => {
  return (
    <div className="table2">
      <table
        className={background ? "tableBorder tableBackground" : "tableBorder"}
      >
        <caption style={{ backgroundColor: color }}>{caption}</caption>
        {rows?.map((row, index) => {
          if (index === 0) {
            return (
              <tr key={`heading-${index}`}>
                {row?.map((heading, headingIndex) => {
                  if (index === 0) {
                    return (
                      <th
                        key={`tableheading-${headingIndex}`}
                        style={heading?.style}
                      >
                        {heading.value}
                      </th>
                    );
                  }
                })}
              </tr>
            );
          } else {
            return (
              <tr key={`heading-${index}`}>
                {row?.map((cell, cellIndex) => {
                  return (
                    <td
                      key={`tableData-${cellIndex}`}
                      style={
                        cellIndex === 0
                          ? { fontWeight: "bold", ...cell?.style }
                          : cell?.style
                          ? cell?.style
                          : {}
                      }
                    >
                      {cell.value}
                    </td>
                  );
                })}
              </tr>
            );
          }
        })}
      </table>
    </div>
  );
};
