import {
  Backdrop,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import Box1, { Banner } from "../home/box1";
import "../home/homeStyle.css";
import { Demo } from "../about/about";
import logo from "../assets/logo_dark.png";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    background: "#fff", //theme.palette.secondary.main, // Specify your desired background color here
  },
  loader: {
    ["@media (min-width:1600px)"]: {
      display: "none",
    },
  },
  image: {
    justifyContent: "flex-start",
    alignItems: "center",
    height: "inherit",
    maxWidth: "150px",
    cursor: "pointer",
  },
}));
export const Loader = () => {
  const [open, setOpen] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setOpen(false);
  //   }, 1000);
  // }, []);
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Banner loader />
        <div>
          {/* <img src={logo} className={classes.image} alt="logo" /> */}
          <Typography variant="h6">Loading ....</Typography>
        </div>
        <Demo />
        <CircularProgress color="secondary" className={classes.loader} />
      </div>
    </Backdrop>
  );
};
