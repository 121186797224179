import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import logo from "../src/assets/logo_dark.png";
import { SwipeableDrawer, Typography } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloseIcon from "@material-ui/icons/Close";
import { ContactComponent, SocialComponent } from "./home/box8";
import { red } from "@material-ui/core/colors";
import { HashLink as Ink } from "react-router-hash-link";
const drawerWidth = 340;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: "50px 50px 0px 50px",
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    minHeight: "80px",
    padding: "18px 18px 0px 0px",
    flexDirection: "column",
  },
  close: {
    alignSelf: "flex-end",
    cursor: "pointer",
  },
  image: {
    justifyContent: "flex-start",
    alignItems: "center",
    height: "inherit",
    maxWidth: "150px",
    cursor: "pointer",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  menuStyle: {
    color: "#333333",
    fontSize: 14,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  list: {
    flexDirection: "column",
  },
  menuItems: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  submenu: {
    paddingLeft: 40,
    fontSize: 13,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  childDivider: {
    border: "0.5px solid red",
    width: "100%",
    height: 1,
    margin: 0,
    flexShrink: 0,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  contact: {
    width: "20%",
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    marginBottom: "20px",
    "@media (max-width:773px)": {
      width: "100%",
    },
  },
  contactInfo: {
    "@media(max-width:773px)": {
      alignSelf: "center",
    },
  },
}));
const Div = styled(SwipeableDrawer)(({ theme, open }) => ({
  maxWidth: drawerWidth,
  "& .PrivateSwipeArea-anchorRight-61": {
    width: 0,
    backgroundColor: "red",
  },
}));
export default function PermanentDrawerRight(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [submenu, setSubmenu] = useState(true);
  const navigateTo = (path) => {
    if (path === "/dashboard") {
      // navigate(path);
    } else if (path) {
      // navigate(path);
      props.setOpen();
      return path;
    } else {
      props.setOpen();
    }
  };
  const handleOutsideClick = (event) => {
    if (props.open && !event.target.closest(".MuiPaper-root")) {
      props.setOpen();
    }
  };
  return (
    <Div
      anchor="right"
      // className={classes.drawer}
      disableSwipeToOpen={true}
      open={props.open}
      onClick={handleOutsideClick}
    >
      <div style={{ padding: "0px 0px 0px 50px" }}>
        <div className={classes.toolbar}>
          <CloseIcon
            color="secondary"
            className={classes.close}
            onClick={() => navigateTo()}
          />
          <img
            src={logo}
            className={classes.image}
            onClick={() => navigateTo("/")}
            alt="logo"
          />
        </div>

        <List>
          {[
            {
              text: "Home",
              path: "/",
              children: [{ name: "FAQ", path: "/#faq" }],
            },

            {
              text: "Examples & Discussion Video",
              path: "/dashboard/demo",
              children: [
                {
                  name: "Test Session Scores General Example",
                  path: "/dashboard/sheet1",
                },
                {
                  name: "Student Dashboard",
                  path: "/dashboard/sheet4",
                },
                { name: "School Outreach", path: "/dashboard/sheet2" },
                { name: "Faculty Survey", path: "/dashboard/sheet3" },
                { name: "Video Discussion", path: "/dashboard/demo" },
              ],
            },
            { text: "About", path: "/about" },
            { text: "Contact us", path: "/contact" },
          ].map((menu, index) => (
            <div key={menu.text} className="list">
              <Ink
                style={{ textDecoration: "none" }}
                to={menu.path}
                onClick={props.setOpen}
              >
                <ListItem
                  button
                  // onClick={() => navigateTo(menu?.path)}
                  className={classes.menuItems}
                >
                  <Typography variant="h5" className={classes.menuStyle}>
                    {menu.text.toUpperCase()}
                  </Typography>
                  {menu?.children && false && (
                    <AddBoxIcon
                      color="secondary"
                      onClick={() => setSubmenu(!submenu)}
                    ></AddBoxIcon>
                  )}
                </ListItem>
              </Ink>
              {submenu &&
                menu?.children?.map((child, index) => {
                  return (
                    <Ink
                      style={{ textDecoration: "none" }}
                      to={child.path}
                      onClick={props.setOpen}
                    >
                      <ListItem
                        button
                        key={`${index}-${child.name}`}
                        // onClick={() => navigateTo(child.path)}
                      >
                        <Typography variant="h5" className={classes.submenu}>
                          {child?.name}
                        </Typography>
                      </ListItem>
                    </Ink>
                  );
                })}
              {/* <Divider /> */}
            </div>
          ))}
        </List>
        {/* <Divider /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            margin: "40px 0px",
          }}
        >
          <Typography variant="h3">Contact Info</Typography>
          <Divider className={classes.contact} />
          <ContactComponent sideMenu={true} />
        </div>
        {/* <SocialComponent /> */}
      </div>
    </Div>
  );
}
