import { Box, Button, TextField, Typography } from "@material-ui/core";
import react from "react";
import { useNavigate } from "react-router-dom";
import background from "../assets/page2Profile.png";
import { Link as Ink } from "react-router-dom";
import { BusinessDetails, Demo, Demo2 } from "../about/about";
let copy =
  "Currently, I am providing data and operational support for schools in California, Texas, Michigan, Virginia, North Carolina, Indiana, Idaho, New York, Louisiana, Missouri and Washington, DC. The data and operations support that I provide for schools varies in scope and depth. I work within the financial and operational parameters of each school. The purpose of this website is simple: to demonstrate that data and operational support is not only possible, but affordable and tailored fully to the needs of your school. There are countless demands and responsibilities required of educators each day. Additionally, at some time, schools experience change and turnover. My service is a safeguard against all these commitments and changes. While educators come and go, the data and support required for your school remains on schedule and effectively completed. Among the services that have been completed to date";
function Box2({ title, body }) {
  const navigate = useNavigate();
  return (
    <Box className={title ? "box2StyleVariant" : "box2Style"}>
      {/* <Box className="profile">
        <img src={background} style={{ width: "80%" }} />
      </Box> */}
      <Box className="profileContent">
        {!title && (
          <Typography variant="h5" className="box2Heading">
            ABOUT SCHOOL DATA BUFFET
          </Typography>
        )}
        <div className="circle">
          {!title && <Demo2 />}
          <div className={title ? "aboutMe" : "responsiveContent"}>
            <Typography variant="h2" className="box2Heading1">
              {title ? title : "Data Analysis, Operations Support & Consulting"}
            </Typography>
            <Typography
              variant="h5"
              style={{
                lineHeight: "28px",
                marginBottom: "32px",
                whiteSpace: "pre-line",
              }}
            >
              {body ? body : <BusinessDetails />}
            </Typography>

            {body && (
              <Typography
                variant="h5"
                style={{
                  lineHeight: "28px",
                  marginBottom: "32px",
                  alignSelf: "flex-end",
                }}
              >
                - Nick Humbert
              </Typography>
            )}

            {false && (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className="box2ReadMore"
                // onClick={() => navigate("/about")}
              >
                read more
              </Button>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default Box2;
