import { Box, Button, TextField, Typography } from "@material-ui/core";
import react, { useEffect, useRef, useState } from "react";
import background from "../assets/background3.png";
import icon from "../assets/icon_3.svg";
import { content1, content2, content3, content4 } from "./boxConsts";
import ScrollDialog from "./box3Dialog";
import ReactGA from "react-ga4";
function Box3() {
  const [content, setContent] = useState({ open: false });

  const handleClick = (content) => {
    ReactGA.event({
      category: "Comprehensive Educational Data Analysis and Operations",
      action: "clicked let's connect get Started",
      label: content?.heading, // optional
    });
    setContent({ ...content, open: !content.open });
  };
  const setOpen = (state) => {
    setContent({ ...content, open: state });
  };
  return (
    <Box className="box3Style">
      <Box className="box3HeaderContainer">
        <Typography variant="h5" className="box3Title">
          Who we are
        </Typography>
        <Typography variant="h2" className="box3Header">
          Comprehensive Educational Data Analysis and Operations
        </Typography>
        {/* <Typography variant="h5" style={{ lineHeight: "28px" }}>
          Tracking student data, analysing standardized test results, creating
          school improvement plans, providing logistical support, and presenting
          data to school officials and the community.
        </Typography> */}
      </Box>
      <div className="box3Grid">
        {/* add line clamping here and show content in a modal, content hovering and showing points */}

        <Row content={content1} onClick={handleClick} />
        <Row content={content2} onClick={handleClick} />
        <Row content={content3} onClick={handleClick} />
        <Row content={content4} onClick={handleClick} />
        <ScrollDialog content={content} setOpen={setOpen} />
      </div>
    </Box>
  );
}

export default Box3;

const Row = (props) => {
  useEffect(() => {
    const elements = document.querySelectorAll(".rowContainer");

    const zoomInOnScroll = () => {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const position = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (position < windowHeight - windowHeight / 4) {
          element.classList.add("zoom-points");
        } else {
          element.classList.remove("zoom-points");
        }
      }
    };

    window.addEventListener("scroll", zoomInOnScroll);
    zoomInOnScroll();

    return () => {
      window.removeEventListener("scroll", zoomInOnScroll);
    };
  }, []);
  const handleClick = () => {
    props.onClick(props.content);
  };

  return (
    <div className="rowContainer" onClick={handleClick}>
      <div className="rowContainer2">
        <img src={icon} className="rowImage" />
        <div className="rowContent">
          <Typography variant="h3">{props?.content.heading}</Typography>
          <Typography variant="h5" className="rowContentBody">
            {props?.content.body}
          </Typography>

          <Typography variant="h5" style={{ color: "blue" }}>
            Read More
          </Typography>
        </div>
      </div>
    </div>
  );
};
