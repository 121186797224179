import { Box, Button, TextField, Typography } from "@material-ui/core";
import react, { useEffect } from "react";
import img1 from "../assets/Box4/1.png";
import img2 from "../assets/Box4/2.png";
import img3 from "../assets/Box4/3.png";
import img4 from "../assets/Box4/4.png";
import img5 from "../assets/Box4/5.png";
import img6 from "../assets/Box4/6.png";
import img7 from "../assets/Box4/7.png";
function Box4() {
  useEffect(() => {
    const elements = document.querySelectorAll(".contentPoint");

    const zoomInOnScroll = () => {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const position = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (position < windowHeight - windowHeight / 4) {
          element.classList.add("zoom-points");
        } else {
          element.classList.remove("zoom-points");
        }
      }
    };

    window.addEventListener("scroll", zoomInOnScroll);
    zoomInOnScroll();

    return () => {
      window.removeEventListener("scroll", zoomInOnScroll);
    };
  }, []);
  return (
    <Box className="box4Style">
      <Box style={{ width: "100%" }}>
        <div style={{ textAlign: "center", marginBottom: "32px" }}>
          <Typography
            variant="h4"
            style={{ color: "#F26723", textTransform: "uppercase" }}
          >
            BUSINESS MODEL
          </Typography>
          <Typography variant="h5" style={{ lineHeight: "28px" }}>
            These beliefs are foundational and unwavering.
          </Typography>
        </div>
        <div className="contentPoint">
          <Box className="imgBox">
            <div className="imgContainer">
              <div className="img1">
                <img src={img1} />
              </div>
              <div className="img2">
                <img src={img2} />
              </div>
              <div className="img3">
                <img src={img3} />
              </div>
              <div className="img4">
                <img src={img4} />
              </div>
              <div className="img5">
                <img src={img5} />
              </div>
              <div className="img6">
                <img src={img6} />
              </div>
              <div className="img7">
                <img src={img7} />
              </div>
            </div>
          </Box>
          <Box style={{ flex: 1 }}>
            <div className="firstPoint">
              <PointComponent
                point="1"
                title="Immediate"
                description="
                Requests are completed immediately. They do not sit for days or weeks. Communication and ensuing work begins after the request is raised. "
              />
            </div>
            <div className="secondPoint">
              <PointComponent
                point="2"
                title="Comprehensive"
                description="Total ownership of the request and deliverable is assumed and the subsequent work will be thorough and complete . "
              />
            </div>
            <div className="thirdPoint">
              <PointComponent
                point="3"
                // color="#F26723"
                title="Effective"
                description="Any subsequent data or deliverable will be clear, concise and informative. All deliverables will be actionable and understandable and provide clarity. "
              />
            </div>
          </Box>
        </div>
      </Box>
    </Box>
  );
}

export default Box4;

export const PointComponent = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: props.color ? props.color : "#173969",
          minWidth: "50px",
          minHeight: "50px",
          margin: "0px 15px",
          borderRadius: "150px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "30px",
          lineHeight: "50px",
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        {props.point}
      </div>
      <div style={{ textAlign: "none" }}>
        <Typography variant="h6">{props.title}</Typography>
        <Typography
          variant="h5"
          style={{ wordBreak: "break-word", lineHeight: "28px" }}
        >
          {props.description}
        </Typography>
      </div>
    </div>
  );
};
