import "../home/homeStyle.css";
import { Chart } from "react-google-charts";
import { Typography } from "@material-ui/core";
import { dataSecond1Legend } from "./sheetData";
import { Legend } from "./sheet3";
import { Loader } from "../components/loader";
import ReactGA from "react-ga4";
import { useEffect } from "react";

export const dataSecond1 = [
  [
    "values",
    "Strongly Agree",
    "Agree",
    "Neutral",
    "Disagree",
    "Strongly Disagree",
  ],
  ["Winter 2022-2023", 44.26, 32.77, 15.74, 5.53, 1.7],
  ["Fall 2021-2022", 45, 21.8, 20.0, 9.5, 3.6],
  ["Spring 2020-2021", 55.6, 25.8, 12.8, 4.0, 1.8],
  ["Fall 2020-2021", 59.3, 34.5, 5.6, 0.6, 0],
];

export const optionsSecond1 = {
  title:
    "The school provides opportunities for students to connect beyong the classroom. ",
  legend: { position: "none" },

  chartArea: { width: "80%", height: "70%" },
  vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
  colors: ["#6AA84F", "#B6D7A8", "#FFF2CC", "#F4CCCC", "#E66666"],
  pointSize: 5,
  isStacked: true,
  seriesType: "bars",
};
export const tableConsts = {
  one: "1 = Strongly Disagree",
  two: "2 = Disagree",
  three: "3 = Neither Agree Nor Disagree",
  four: "4 = Agree",
  five: "5 = Strongly Agree",
};
export const data39 = [
  ["Task", "Hours per Day"],
  [tableConsts.five, 59.3],
  [tableConsts.four, 34.5],
  [tableConsts.three, 5.6],
  [tableConsts.two, 0.6],
  [tableConsts.one, 0.0],
];
export const data40 = [
  ["Task", "Hours per Day"],
  [tableConsts.five, 55.6],
  [tableConsts.four, 25.8],
  [tableConsts.three, 12.9],
  [tableConsts.two, 4.0],
  [tableConsts.one, 1.8],
];
export const data41 = [
  ["Task", "Hours per Day"],
  [tableConsts.five, 45.0],
  [tableConsts.four, 21.8],
  [tableConsts.three, 20.0],
  [tableConsts.two, 9.5],
  [tableConsts.one, 3.6],
];
export const options39 = {
  title: "Fall 2020-2021: Pct",
  is3D: false,

  colors: ["#6AA84F", "#B6D7A8", "#FFF2CC", "#F4CCCC", "#E66666"],
  animation: {
    duration: 3000,
    easing: "out",
    startup: true,
  },
};
export const options40 = {
  title: "Spring 2020-2021: Pct",
  is3D: false,

  colors: ["#6AA84F", "#B6D7A8", "#FFF2CC", "#F4CCCC", "#E66666"],
  animation: {
    duration: 3000,
    easing: "out",
    startup: true,
  },
};
export const options41 = {
  title: "Fall 2021-2022: Pct",
  is3D: false,

  colors: ["#6AA84F", "#B6D7A8", "#FFF2CC", "#F4CCCC", "#E66666"],
  animation: {
    duration: 3000,
    easing: "out",
    startup: true,
  },
};

function Sheet2() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Visual All Students",
    });
  }, []);
  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 600 }}>
        Visual All Students
      </Typography>
      <div className="sheet3">
        <div className="graphWithLegend">
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="500px"
            loader={<Loader />}
            data={dataSecond1}
            options={optionsSecond1}
          />
          <Legend legends={dataSecond1Legend} />
        </div>
        <div className="annotationTables">
          <table className="tableBorder">
            <tr className="boldText sheet2annotationHeader">
              <th>Winter 2022-2023 Responses</th>
              <th>Pct</th>
            </tr>
            <tr className="boldText sheet2r5">
              <td>{tableConsts.five}</td>
              <td>44.26%</td>
            </tr>
            <tr className="boldText sheet2r4">
              <td>{tableConsts.four}</td>
              <td>32.77%</td>
            </tr>
            <tr className="boldText sheet2r3">
              <td>{tableConsts.three}</td>
              <td>15.74%</td>
            </tr>
            <tr className="boldText sheet2r2">
              <td>{tableConsts.two}</td>
              <td>5.53%</td>
            </tr>
            <tr className="boldText sheet2r1">
              <td>{tableConsts.one}</td>
              <td>1.70%</td>
            </tr>
          </table>
          <table className="tableBorder">
            <tr className="sheet2annotationHeader">
              <th>Winter 2022-2023 </th>
              <th>Responses</th>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.five}</td>
              <td>104</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.four}</td>
              <td>77</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.three}</td>
              <td>37</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.two}</td>
              <td>13</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.one}</td>
              <td>4</td>
            </tr>
          </table>
          <table className="tableBorder">
            <tr className="sheet2annotationHeader">
              <th>Winter 2022-2023 </th>
              <th>Avg</th>
            </tr>
            <tr className="boldText sheet2-1">
              <td>4 & 5 Percentage</td>
              <td className="boldText">77.02%</td>
            </tr>
            <tr className="boldText sheet2-2">
              <td>3 Percentage</td>
              <td className="boldText">15.74%</td>
            </tr>
            <tr className="boldText sheet2-3">
              <td>1 & 2 Percentage</td>
              <td className="boldText">7.23%</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="sheet2otherTables">
        <div className="otherColumn">
          <Chart
            chartType="PieChart"
            data={data39}
            options={options39}
            width={"100%"}
            height={"400px"}
          />
          {/* <table className="tableBorder">
            <tr className="sheet2otherTablesHeader">
              <th>Fall 2020-2021 </th>
              <th>Pct</th>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.five}</td>
              <td>59.30%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.four}</td>
              <td>34.50%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.three}</td>
              <td>5.60%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.two}</td>
              <td>0.60%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.one}</td>
              <td>0.00%</td>
            </tr>
          </table> */}
        </div>
        <div className="otherColumn">
          <Chart
            chartType="PieChart"
            data={data40}
            options={options40}
            width={"100%"}
            height={"400px"}
          />
          {/* <table className="tableBorder">
            <tr className="sheet2otherTablesHeader">
              <th>Spring 2020-2021 </th>
              <th>Pct</th>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.five}</td>
              <td>55.60%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.four}</td>
              <td>25.80%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.three}</td>
              <td>12.90%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.two}</td>
              <td>4.00%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.one}</td>
              <td>1.80%</td>
            </tr>
          </table> */}
        </div>
        <div className="otherColumn">
          <Chart
            chartType="PieChart"
            data={data41}
            options={options41}
            width={"100%"}
            height={"400px"}
          />
          {/* <table className="tableBorder">
            <tr className="sheet2otherTablesHeader">
              <th>Fall 2021-2022 </th>
              <th>Pct</th>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.five}</td>
              <td>45.00%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.four}</td>
              <td>21.80%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.three}</td>
              <td>20.00%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.two}</td>
              <td>9.50%</td>
            </tr>
            <tr className="boldText">
              <td>{tableConsts.one}</td>
              <td>3.60%</td>
            </tr>
          </table> */}
        </div>
      </div>
    </>
  );
}

export default Sheet2;
