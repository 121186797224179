import { Box, Button, Divider, TextField, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import Box8, {
  ContactComponent,
  SocialComponent,
  SocialComponent2,
} from "../home/box8";
import "./contact.css";
import { useStyles } from "../drawer";
import { useEffect, useState } from "react";
import { validEmail } from "../utils/validators";
import ReactGA from "react-ga4";
function ContactUs() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "contact us page",
    });
  }, []);
  return (
    <Box className="contactPageContainer">
      <Header heading="Contact With Us" page="Contact" />

      <div className="cards">
        {/* <Card1
          head="Stay Connected"
          content="Bring to the table win-win survival strateg to ensure domination. At the & of the day, going forward."
        /> */}
        <Card1
          contact={true}
          head="Contact Info"
          content="42669 Cochrans Lock Drive Ashburn, Virginia 20148, USA"
        />
      </div>
      <div className="contactForm">
        <ContactFormComponent />
        {/* <ContactFormComponent disabled={false} /> */}
      </div>
      <Box8 />
    </Box>
  );
}

export default ContactUs;
export const Header = (props) => {
  const navigate = useNavigate();

  return (
    <Box className="contactImage">
      <Typography variant="h1" className="contactHeader">
        {props?.heading}
      </Typography>
      <Typography variant="h5" className="contactSubHeader">
        <span style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          Home
        </span>{" "}
        / {props?.page}
      </Typography>
    </Box>
  );
};

const Card1 = (props) => {
  const classes = useStyles();
  return (
    <Box className="contactCard">
      <Typography
        variant="h3"
        // style={{
        //   lineHeight: "29px",

        //   marginBottom: "5px",
        //   fontSize: "30px",
        // }}
        className="contactCardHead"
      >
        {props?.head}
      </Typography>
      <Divider className={classes.contact} />
      <Typography variant="h5" className="contactCardContent">
        {props?.content}
      </Typography>

      {props.contact && <ContactComponent sideMenu={true} />}
      {!props.contact && <SocialComponent label={true} />}
    </Box>
  );
};
const dataTemplate = {
  name: "",
  email: "",
  message: "",
  phone: "",
  subject: "",
};
export const ContactFormComponent = ({
  text = "Send Message",
  disabled = true,
}) => {
  const [data, setData] = useState(dataTemplate);
  const handleChange = (event) => {
    let obj = {};
    switch (event.target.name) {
      case "name":
        obj = { ...data, name: event.target.value };
        break;
      case "message":
        obj = { ...data, message: event.target.value };
        break;
      case "email":
        obj = { ...data, email: event.target.value };
        break;
      case "phone":
        obj = { ...data, phone: event.target.value };
        break;
      default:
        obj = { ...data, subject: event.target.value };
        break;
    }
    setData({ ...obj });
  };
  const handleSubmit = async () => {
    if (data.name.length > 0 && validEmail(data.email)) {
      try {
        const docRef = await addDoc(collection(db, "Leads"), {
          ...data,
        });
        setData({ ...dataTemplate });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      window.alert(`Please atleast check your name and email id`);
    }
  };
  const manageMail = () => {
    ReactGA.event({
      category: "About us",
      action: "clicked mail link under about us",
      label: "Keep in Touch With Us", // optional
      nonInteraction: false, // optional, true/false
    });
  };

  return (
    <Box className={false ? "contactFormText" : "contactFormContainer"}>
      {disabled && (
        <div>
          <Typography variant="h6" className="contactFormHeader">
            Keep in Touch With Us
          </Typography>
          <Typography variant="h5" className="formNote">
            Feel free to reach out directly by emailing me at{" "}
            <a
              href="mailto:nick@schooldatabuffet.com"
              target="_blank"
              className="wordBreak"
              onClick={manageMail}
            >
              nick@schooldatabuffet.com
            </a>{" "}
            or just call/text @ 703.439.9662. Any inquiry will be answered the
            same day. I cannot emphasize this enough. Let’s connect and see what
            you need.{" "}
          </Typography>
        </div>
      )}
      {!disabled && (
        <Box className="contactFormContainer1">
          <div className="contactFormRow">
            <TextField
              name="name"
              className="contactFormInput"
              placeholder="Name"
              value={data.name}
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              name="email"
              className="contactFormInput"
              placeholder="Email"
              value={data.email}
              variant="outlined"
              onChange={handleChange}
            />
          </div>
          <div className="contactFormRow">
            <TextField
              name="phone"
              value={data.phone}
              className="contactFormInput"
              placeholder="Phone Number"
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              name="subject"
              value={data.subject}
              className="contactFormInput"
              placeholder="Subject"
              variant="outlined"
              onChange={handleChange}
            />
          </div>
          <div className="contactFormRow">
            <TextField
              name="message"
              className="contactFormInputMessage"
              placeholder="Your Message Here"
              multiline
              value={DataTransferItem.message}
              rows={4}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </div>
          <Button
            className="contactFormButton"
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
          >
            {text}
          </Button>
        </Box>
      )}
    </Box>
  );
};
