const table0Header = [
  {
    value: "Student Name",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Student Last Name, Student First Name",
    style: {
      fontWeight: "bold",
      color: "#FFF",
      backgroundColor: "rgba(255,0,255,0.9)",
    },
  },
];
const table12Header = [
  { value: "Category" },
  { value: "22-23 EOY" },
  { value: "22-23 MOY" },
  { value: "22-23 BOY" },
  { value: "21-22 EOY" },
  { value: "21-22 MOY" },
  { value: "21-22 BOY" },
];
const table34Header = [
  { value: "Category" },
  { value: "Spring 22-23" },
  { value: "Spring 21-22" },
  { value: "Spring 20-21" },
];
const table6Header = [
  { value: "Category" },
  { value: "Math 22-23" },
  { value: "Math 21-22" },
  { value: "Math 20-21" },
  { value: "Reading 22-23" },
  { value: "Reading 21-22" },
  { value: "Reading 20-21" },
];
const table7Header = [
  {
    value: "iReady: Math",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Strand",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Strand Abbreviation",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
];

const table8Header = [
  {
    value: "iReady: Reading",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Strand",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Strand Abbreviation",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
];
const table9Header = [
  {
    value: "CAASP: Math",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Strand",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Strand Abbreviation",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
];
const table10Header = [
  {
    value: "CASSP: Reading",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Strand",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
  {
    value: "Strand Abbreviation",
    style: {
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "rgba(255,191,0,0.5)",
    },
  },
];
export const sheet4Table0 = [
  table0Header,

  [{ value: "Grade" }, { value: "8" }],

  [{ value: "ELL" }, { value: "Y" }],
  [{ value: "IEP" }, { value: "N" }],

  [{ value: "Lexile" }, { value: "Y" }],
];
export const sheet4Table1 = [
  table12Header,
  [
    { value: "Overall Score" },
    { value: "468" },
    { value: "458" },
    { value: "456" },
    { value: "450" },
    { value: "449" },
    { value: "453" },
  ],

  [
    { value: "Overall Placement" },
    { value: "Level 5" },
    { value: "Level 4" },
    { value: "Level 4" },
    { value: "Level 4" },
    { value: "Level 3" },
    { value: "Level 4" },
  ],

  [
    { value: "Percentile", style: { fontWeight: "bold" } },
    {
      value: "57",
      style: {
        fontWeight: "bold",
        color: "#FFF",
        backgroundColor: "rgba(51,177,51,0.5)",
      },
    },
    {
      value: "51",
      style: {
        fontWeight: "bold",
        color: "#FFF",
        backgroundColor: "rgba(51,177,51,0.5)",
      },
    },
    {
      value: "52",
      style: {
        fontWeight: "bold",
        color: "#FFF",
        backgroundColor: "rgba(51,177,51,0.5)",
      },
    },
    {
      value: "48",
      style: { fontWeight: "bold", color: "#000", backgroundColor: "#FFF" },
    },
    {
      value: "22",
      style: {
        fontWeight: "bold",
        color: "#000",
        backgroundColor: "rgba(241,77,143,0.5)",
      },
    },
    {
      value: "13",
      style: {
        fontWeight: "bold",
        color: "#000",
        backgroundColor: "rgba(241,77,143,0.5)",
      },
    },
  ],
  [
    { value: "Number & Operations" },
    { value: "Level 5" },
    { value: "Level 5" },
    { value: "Level 5" },
    { value: "Level 5" },
    { value: "Level 4" },
    { value: "Level 5" },
  ],
  [
    { value: "Algebra" },
    { value: "Level 5" },
    { value: "Level 5" },
    { value: "Level 4" },
    { value: "Level 4" },
    { value: "Level 3" },
    { value: "Level 2" },
  ],
  [
    { value: "Measure & Data" },
    { value: "Level 5" },
    { value: "Level 4" },
    { value: "Level 3" },
    { value: "Level 3" },
    { value: "Level 3" },
    { value: "Level 4" },
  ],
  [
    { value: "Geometry" },
    { value: "Level 4" },
    { value: "Level 2" },
    { value: "Level 3" },
    { value: "Level 2" },
    { value: "Level 4" },
    { value: "Level 5" },
  ],
];
export const sheet4Table2 = [
  table12Header,

  [
    { value: "Overall Score" },
    { value: "574" },
    { value: "526" },
    { value: "497" },
    { value: "555" },
    { value: "470" },
    { value: "521" },
  ],

  [
    { value: "Overall" },
    { value: "Level 5" },
    { value: "Level 3" },
    { value: "Level 3" },
    { value: "Level 4" },
    { value: "Level 1" },
    { value: "Level 3" },
  ],

  [
    { value: "Percentile", style: { fontWeight: "bold" } },
    {
      value: "53",
      style: {
        fontWeight: "bold",
        color: "#FFF",
        backgroundColor: "rgba(51,177,51,0.5)",
      },
    },
    {
      value: "51",
      style: {
        fontWeight: "bold",
        color: "#FFF",
        backgroundColor: "rgba(51,177,51,0.5)",
      },
    },
    {
      value: "44",
      style: { fontWeight: "bold", color: "#000", backgroundColor: "#FFF" },
    },
    {
      value: "21",
      style: { fontWeight: "bold", color: "#000", backgroundColor: "#F14D8F" },
    },
    {
      value: "9",
      style: {
        fontWeight: "bold",
        color: "#000",
        backgroundColor: "rgba(241,77,143,0.5)",
      },
    },
    {
      value: "13",
      style: {
        fontWeight: "bold",
        color: "#000",
        backgroundColor: "rgba(241,77,143,0.5)",
      },
    },
  ],
  [
    { value: "Voc" },
    { value: "Tested Out" },
    { value: "Tested Out" },
    { value: "Level 3" },
    { value: "Level 7" },
    { value: "Level 3" },
    { value: "Level 4" },
  ],
  [
    { value: "Literature" },
    { value: "Tested Out" },
    { value: "Tested Out" },
    { value: "Level 3" },
    { value: "Level 3" },
    { value: "Level K" },
    { value: "Level 3" },
  ],
  [
    { value: "Info Text" },
    { value: "Tested Out" },
    { value: "Tested Out" },
    { value: "Level 1" },
    { value: "Level 3" },
    { value: "Level 2" },
    { value: "Level 2" },
  ],
];
export const sheet4Table3 = [
  table34Header,

  [
    { value: "Overall Score" },
    { value: "6867" },
    { value: "6433" },
    { value: "6266" },
  ],

  [
    { value: "Overall Proficiency" },
    { value: "Proficient" },
    { value: "Below Proficiency" },
    { value: "Below Proficiency" },
  ],

  [
    { value: "Alg Thinking" },
    { value: "Meets" },
    { value: "At/Near" },
    { value: "At/Near" },
  ],

  [
    { value: "Computation" },
    { value: "Meets" },
    { value: "Below" },
    { value: "Below" },
  ],

  [
    { value: "Geo-Measurement" },
    { value: "Meets" },
    { value: "At/Near" },
    { value: "At/Near" },
  ],

  [
    { value: "Number Sense" },
    { value: "Meets" },
    { value: "Below" },
    { value: "Below" },
  ],
];
export const sheet4Table4 = [
  table34Header,

  [
    { value: "Overall Score" },
    { value: "6867" },
    { value: "6433" },
    { value: "6266" },
  ],

  [
    { value: "Overall Proficiency" },
    { value: "Proficient" },
    { value: "Below Proficiency" },
    { value: "Below Proficiency" },
  ],

  [
    { value: "Key Ideas & TS" },
    { value: "Meets" },
    { value: "At/Near" },
    { value: "At/Near" },
  ],

  [
    { value: "Str Elements" },
    { value: "Meets" },
    { value: "Below" },
    { value: "Below" },
  ],

  [
    { value: "Writing" },
    { value: "Meets" },
    { value: "At/Near" },
    { value: "At/Near" },
  ],
];
export const sheet4Table6 = [
  table6Header,

  [
    { value: "Lessons" },
    { value: "1" },
    { value: "37" },
    { value: "43" },
    { value: "3" },
    { value: "63" },
    { value: "16" },
  ],

  [
    { value: "Minutes" },
    { value: "10" },
    { value: "1215" },
    { value: "1726" },
    { value: "124" },
    { value: "1418" },
    { value: "810" },
  ],

  [
    { value: "Passed" },
    { value: "1" },
    { value: "17" },
    { value: "14" },
    { value: "1" },
    { value: "52" },
    { value: "11" },
  ],

  [
    { value: "Not Passed" },
    { value: "0" },
    { value: "20" },
    { value: "29" },
    { value: "2" },
    { value: "11" },
    { value: "5" },
  ],
];
export const sheet4Table7 = [
  table7Header,

  [
    { value: "Strand 1" },
    { value: "Number and Operations" },
    { value: "Number & Operations" },
  ],

  [
    { value: "Strand 2" },
    { value: "Algebra and Algebraic Thinking" },
    { value: "Algebra" },
  ],

  [
    { value: "Strand 3" },
    { value: "Measurement and Data" },
    { value: "Measure & Data" },
  ],

  [{ value: "Strand 4" }, { value: "Geometry" }, { value: "Geometry" }],
];
export const sheet4Table8 = [
  table8Header,

  [{ value: "Strand 1" }, { value: "Vocabulary" }, { value: "Voc" }],

  [
    { value: "Strand 2" },
    { value: "Reading Comprehension: Literature Relative Placement" },
    { value: "Literature" },
  ],

  [
    { value: "Strand 3" },
    { value: "Reading Comprehension: Informational Text Relative Placement" },
    { value: "Info Text" },
  ],
];
export const sheet4Table9 = [
  table9Header,

  [
    { value: "Strand 1 Grades 3-5" },
    { value: "Algebraic Thinking" },
    { value: "Alg Thinking" },
  ],

  [
    { value: "Strand 1 Grades 6-8" },
    { value: "Algebraic Functions" },
    { value: "Alg Function" },
  ],

  [
    { value: "Strand 2 Grades 3-5" },
    { value: "Computation" },
    { value: "Computation" },
  ],
  [
    { value: "Strand 2 Grades 6-8" },
    { value: "Data, Statistics, & Probability" },
    { value: "Data/Stat" },
  ],

  [
    { value: "Strand 3 Grades 3-8" },
    { value: "Geometry and Measurement" },
    { value: "Geo-Measurement" },
  ],
  [
    { value: "Strand 4 Grades 3-8" },
    { value: "Number Sense" },
    { value: "Number Sense" },
  ],
];
export const sheet4Table10 = [
  ...sheet4Table7,
  ...sheet4Table8,
  ...sheet4Table9,
  table10Header,

  [
    { value: "Strand 1" },
    { value: "Key Ideas & Textual Support" },
    { value: "Key Ideas & TS" },
  ],

  [
    { value: "Strand 2" },
    { value: "Structual Elements and Connection of Ideas" },
    { value: "Str Elements" },
  ],

  [{ value: "Strand 3" }, { value: "Writing" }, { value: "Writing" }],
];
