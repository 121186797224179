// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBhTk_VmAde-RZHHbs8nlc_sR73ywjJQOY",
  authDomain: "studentbuffet-25c6e.firebaseapp.com",
  projectId: "studentbuffet-25c6e",
  storageBucket: "studentbuffet-25c6e.appspot.com",
  messagingSenderId: "888535474494",
  appId: "1:888535474494:web:60832aae3b64d89c920c6f",
  measurementId: "G-FEHEP85W8B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
