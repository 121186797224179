import { createBrowserRouter } from "react-router-dom";
import About from "./about/about";
import Dashboard from "./dashboard/dashboard";
import App from "./App";
import ContactUs from "./contactUs/contactUs";
import { Home } from "./home";
import Sheet1 from "./dashboard/sheet1";
import Sheet2 from "./dashboard/sheet2";
import Sheet3 from "./dashboard/sheet3";
import Sheet4 from "./dashboard/sheet4";
import Box6 from "./home/box6";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
          {
            path: "/dashboard/demo",
            element: <Box6 id="TzBfS4eYekk" title="Video Discussion" />,
          },
          { path: "/dashboard/sheet1", element: <Sheet1 /> },
          { path: "/dashboard/sheet2", element: <Sheet2 /> },
          { path: "/dashboard/sheet3", element: <Sheet3 /> },
          { path: "/dashboard/sheet4", element: <Sheet4 /> },
        ],
      },
      { path: "/about", element: <About /> },
      { path: "/contact", element: <ContactUs /> },
    ],
  },
]);
