import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../assets/logo_dark.png";
import menuIcon from "../../assets/menuButton.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import "./appBar.css";
import { Navigate, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "#FFFFFF",
    },
  },

  menuButton: {
    marginRight: theme.spacing(0),
    color: theme.palette.secondary.main,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const menuOptions = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about" },
    { name: "Dashboard", link: "/dashboard" },
    { name: "Contact", link: "/contact" },
  ];
  window.addEventListener("scroll", () => {
    if (window.scrollY > 102) {
      document.querySelector(".MuiAppBar-colorPrimary").style.background =
        "#FFFFFF";
      document.querySelector(".MuiPaper-elevation4").style.boxShadow =
        " 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)";
      document.querySelector(".MuiAppBar-positionStatic").style.position =
        "fixed";
    } else {
      document.querySelector(".MuiPaper-elevation4").style.boxShadow = "none";
      document.querySelector(".MuiAppBar-positionStatic").style.position =
        "absolute";
      document.querySelector(".MuiAppBar-colorPrimary").style.background =
        "transparent";
    }
  });
  //need to add dropdowns and hovering effects on color change
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar
          style={{
            justifyContent: "space-between",
            paddingRight: 0,
          }}
        >
          <img
            src={logo}
            onClick={() => navigate("/")}
            className="logo"
            alt="logo"
          />
          {/* <div>
            {menuOptions.map((option) => {
              return (
                <Button
                  color="inherit"
                  className={classes.menuButton}
                  key={option.name}
                >
                  {option.name}
                </Button>
              );
            })}
          </div> */}
          <div onClick={props.setOpen} style={{ cursor: "pointer" }}>
            <img src={menuIcon} alt="logo" />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
