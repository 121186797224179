import { AboutBusiness } from "./about/about";
import { BusinessDetails } from "./about/about";
import { Loader } from "./components/loader";
// import Box1 from "./home/box1";
import Box2 from "./home/box2";
import Box3 from "./home/box3";
import Box4 from "./home/box4";
import Box5 from "./home/box5";
import Box6 from "./home/box6";
import Box7 from "./home/box7";
import Box8 from "./home/box8";
import SlideVertically from "./home/slideVertically";
import React, { Suspense, useEffect } from "react";
import ReactGA from "react-ga4";
const LazyLoadComponent = React.lazy(() => import("./home/box1"));

export const Home = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Home Page",
    });
  }, []);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <LazyLoadComponent />
      </Suspense>
      {/*   <Box1 /> */}
      {/* <AboutBusiness/> */}
      <Box2 />

      <Box3 />

      <Box4 />

      <Box5 />

      <Box6 />

      <Box7 />

      <Box8 />
    </>
  );
};
