import { Box, Button, TextField, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CallIcon from "@material-ui/icons/Call";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DoneIcon from "@material-ui/icons/Done";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { theme } from "../App";
import ReactGA from "react-ga4";
function Box8() {
  return (
    <Box className="box7Style">
      <Box className="contactContainer">
        <div className="box8Column">
          <Typography variant="h3" className="box8ContactHeader">
            contact us
          </Typography>
          <ContactComponent />
        </div>
        {/* <div className="box8Column">
          <Typography variant="h3" className="box8ContactHeader">
            Navigation
          </Typography>
          <CheckComponent text="Ashburn, Virginia"></CheckComponent>
          <CheckComponent text="Company overview"></CheckComponent>
          <CheckComponent text="Our vision"></CheckComponent>
          <CheckComponent text="Affiliate program"></CheckComponent>
        </div>
        <div className="box8Column">
          <Typography variant="h3" className="box8ContactHeader">
            Privacy and tos
          </Typography>
          <CheckComponent text="Privacy"></CheckComponent>
          <CheckComponent text="Terms and conditions"></CheckComponent>
          <CheckComponent text="GDPR"></CheckComponent>
          <CheckComponent text="Disclaimer"></CheckComponent>
        </div> */}
      </Box>
      {/* <div style={{ alignSelf: "center" }}>
        <SocialComponent2 />
      </div> */}
      <Typography variant="h5" style={{ alignSelf: "center" }}>
        © 2023 All Rights Reserved.
      </Typography>
    </Box>
  );
}

export default Box8;
export const ContactComponent = (props) => {
  const manageMail = () => {
    ReactGA.event({
      category: "contact",
      action: "clicked mail link under contact us",
      label: "clicked to mail", // optional
      nonInteraction: true, // optional, true/false
    });
  };
  return (
    <div
      className={
        props.sideMenu ? "contactUsRowViewSideMenu" : "contactUsRowView"
      }
    >
      <div className="box8ContactContainer">
        <span className="box8ContactIconContainer">
          <LocationOnIcon
            style={{
              color: props.sideMenu
                ? theme.palette.text.secondary
                : theme.palette.primary.dark,
            }}
            fontSize="small"
          />
        </span>
        <Typography variant="h5">Ashburn, Virginia 20148</Typography>
      </div>
      <div className="box8ContactContainer">
        <span className="box8ContactIconContainer">
          <CallIcon
            style={{
              color: props.sideMenu
                ? theme.palette.text.secondary
                : theme.palette.primary.dark,
            }}
            fontSize="small"
          />
        </span>
        <Typography variant="h5">+1 703 439 9662 (call or text)</Typography>
      </div>
      <div className="box8ContactContainer">
        <span className="box8ContactIconContainer">
          <MailOutlineIcon
            style={{
              color: props.sideMenu
                ? theme.palette.text.secondary
                : theme.palette.primary.dark,
            }}
            fontSize="small"
          />
        </span>
        <Typography variant="h5">
          <a
            href="mailto:nick@schooldatabuffet.com"
            target="_blank"
            className="wordBreak"
            onClick={manageMail}
          >
            nick@schooldatabuffet.com
          </a>
        </Typography>
      </div>
    </div>
  );
};
export const SocialComponent = (props) => {
  return (
    <div
      className={
        props.label
          ? "contactUsSocial"
          : "box8ContactContainer box8SocialContact"
      }
    >
      <div className="socialIcons">
        <FacebookIcon
          size="small"
          style={{
            color: props.label
              ? theme.palette.text.secondary
              : theme.palette.primary.dark,
          }}
        />
        {props.label && <Typography variant="h5">Facebook</Typography>}
      </div>
      <div className="socialIcons">
        <TwitterIcon
          size="small"
          style={{
            color: props.label
              ? theme.palette.text.secondary
              : theme.palette.primary.dark,
          }}
        />
        {props.label && <Typography variant="h5">Twitter</Typography>}
      </div>
      <div className="socialIcons">
        <LinkedInIcon
          size="small"
          style={{
            color: props.label
              ? theme.palette.text.secondary
              : theme.palette.primary.dark,
          }}
        />
        {props.label && <Typography variant="h5">LinkedIn</Typography>}
      </div>
      <div className="socialIcons">
        <InstagramIcon
          size="small"
          style={{
            color: props.label
              ? theme.palette.text.secondary
              : theme.palette.primary.dark,
          }}
        />
        {props.label && <Typography variant="h5">Instagram</Typography>}
      </div>
    </div>
  );
};
export const SocialComponent2 = () => {
  return (
    <div className="">
      <FacebookIcon size="small" color="secondary" />
      <TwitterIcon size="small" color="secondary" />
      <LinkedInIcon size="small" color="secondary" />
      <InstagramIcon size="small" color="secondary" />
    </div>
  );
};

const CheckComponent = ({ text }) => {
  return (
    <div className="box8ContactContainer">
      <span>
        <DoneIcon color="secondary" fontSize="small" />
      </span>
      <Typography variant="h5">{text}</Typography>
    </div>
  );
};
