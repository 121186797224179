export const dataThird1Legend = [
  { label: "Spring 21-22 Group Avg", color: "rgba(92, 133, 214, 1)" },
  { label: "Spring 20-21 School Avg", color: "rgba(255, 0, 0, 1)" },
  { label: "Fall 20-21 Group Avg", color: "rgba(255, 173, 51, 1)" },
  { label: "Fall 21-22 Group Avg", color: "rgba(64, 171, 70, 1)" },
  { label: "Spring 19-20 School Avg", color: "rgba(173, 51, 173, 1)" },
];
export const dataSecond1Legend = [
  { label: "Strongly Agree", color: "#6AA84F" },
  { label: "Agree", color: "#B6D7A8" },
  { label: "Neutral", color: "#FFF2CC" },
  { label: "Disagree", color: "#F4CCCC" },
  { label: "Strongly Disagree", color: "#E66666" },
];
export const dataLegend = [
  { label: "Pct High Achievement", color: "#5C85D6" },
  { label: "Winter School Average", color: "#E36141" },
  { label: "Fall 2022-2023 Pct", color: "#FFAD33" },
];
export const data5Legend = [
  { label: "National Percent Average (Winter)", color: "#5C85D6" },
  { label: "Winter School Average", color: "#E36141" },
  { label: "Fall 2022-2023 Avg", color: "#FFAD33" },
];
export const data9Legend = [
  { label: "K", color: " #5C85D6" },
  { label: "1st Grade", color: "#E36141" },
];
export const data10Legend = [
  { label: "2nd Grade", color: "#5C85D6" },
  { label: "3rd Grade", color: "#E36141" },
  { label: "4th Grade", color: "#FFAD33" },
  { label: "5th Grade", color: "#33B133" },
  { label: "6th Grade", color: "#B933B1" },
  { label: "7th Grade", color: "#33ADD6" },
  { label: "8th Grade", color: "#F14D8F" },
];
export const data11Legend = [
  { label: "K", color: "#5C85D6" },
  { label: "1st Grade", color: "#E36141" },
  { label: "2nd Grade", color: "#FFAD33" },
  { label: "3rd Grade", color: "#33B133" },
  { label: "4th Grade", color: "#B933B1" },
  { label: "5th Grade", color: "#33ADD6" },
];
export const data12Legend = [
  { label: "6th Grade", color: "#5C85D6" },
  { label: "7th Grade", color: "#E36141" },
  { label: "8th Grade", color: " #FFAD33" },
];
export const data17Legend = [
  { label: "Winter 2022-2023", color: " #5C85D6" },
  { label: "Fall 2022-2023", color: "#E36141" },
  { label: "Spring 2021-2022", color: " #FFAD33" },
  { label: "Fall 2021-2022", color: "#33B133" },
];
