import { Box, Button, TextField, Typography } from "@material-ui/core";
import react from "react";
import background from "../assets/footer.png";
import { box7Content } from "./boxConsts";
import ReactGA from "react-ga4";
function Box7() {
  const manageMail = () => {
    ReactGA.event({
      category: "contact",
      action: "clicked let's connect get Started",
      label: "clicked to mail", // optional
      nonInteraction: true, // optional, true/false
    });
    window.open("mailto:nick@schooldatabuffet.com");
  };
  return (
    <Box className="box7Project">
      <div className="projectContainer">
        <Typography variant="h2" className="box7Heading">
          {box7Content?.heading || "We Like to Start Your Project With Us"}
        </Typography>
        <Typography variant="h4" className="box7Heading1">
          {box7Content?.body ||
            "Data Analysis, Consulting, Logistic, Standard Test Analysis and anything else you may need"}
        </Typography>
      </div>
      <div className="button">
        <Button
          variant="contained"
          color="secondary"
          className="box7ReadMore"
          onClick={manageMail}
        >
          get started
        </Button>
      </div>
    </Box>
  );
}

export default Box7;
