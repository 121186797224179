import React, { useEffect, useState } from "react";
import "./App.css";
import ButtonAppBar from "./components/appBar/appBar";
import { createMuiTheme, createTheme, ThemeProvider } from "@material-ui/core";
import ScrollToHashElement from "./scrollToHashElement";
import "../src/home/homeStyle.css";
import PermanentDrawerRight from "./drawer";
import { Outlet } from "react-router-dom";
import ReactGA from "react-ga4";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#edd4c9",
      dark: "#e46430",
      contrastText: "#2d2d2d",
    },
    secondary: {
      main: "#F26723",
      dark: "#f67136",
    },
    text: {
      primary: "#333333",
      secondary: "#5b5b5b",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontSize: "60px",
      fontFamily: "Poppins",
      fontWeight: 500,
      ["@media (max-width: 600px)"]: {
        fontSize: "30px",
      },
    },
    h2: {
      fontSize: "42px",
      fontFamily: "Poppins",
      fontWeight: 600,
      ["@media (max-width: 600px)"]: {
        fontSize: "22px",
      },
    },
    h3: {
      fontSize: "20px",
      fontFamily: "Poppins",
      color: "#333333",
      fontWeight: 600,
      ["@media (max-width: 600px)"]: {
        fontSize: "15px",
      },
    },
    h4: {
      fontSize: "18px",
      fontFamily: "Poppins",
      color: "#666666",
      fontWeight: 400,
    },
    h5: {
      fontSize: "15px",
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "#666666",
      lineHeight: "35px",
    },
    h6: {
      fontSize: "22px",
      fontFamily: "Poppins",
      fontWeight: 600,
      color: "#333333",
    },
  },
});
const MEASUREMENT_ID = "G-WW5CZH31N6";
ReactGA.initialize(MEASUREMENT_ID);

function App() {
  const [open, setOpen] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <ButtonAppBar setOpen={() => setOpen(true)} />
      <ScrollToHashElement />
      {<PermanentDrawerRight open={open} setOpen={() => setOpen(false)} />}
      <Outlet />
    </ThemeProvider>
  );
}

export default App;
